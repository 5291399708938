import { catSort, flattenObj } from "./utils"
import moment from "moment/moment"



export const calculatePriceFromSpend = (spend) => {
    const { currency, montant, devise } = spend
    let chf = 0.00
    let eur = 0.00
    const calc = (amount, rate) => {
        let montant = parseInt(amount * 100)
        let taux = parseInt(rate * 100)
        const result = montant * taux
        return result / (100 * 100)
    }
    if (devise === 'CHF') {
        let rate = currency.chfeur
        chf = calc(montant, 1)
        eur = calc(montant, rate)
    } else if (devise === 'EUR') {
        let rate = currency.eurchf
        eur = calc(montant, 1)
        chf = calc(montant, rate)
    }
    return {
        eur,
        chf
    }
}
export const resolveLieux = (data) => {
    if (!data || !data.length) {
        return
    }
    const flat = flattenObj(data, ['created_at', 'updated_at'])
    return flat
}

export const resolveCategories = (data) => {
    // console.log('Resolveur Data input: ', data)
    if (!data || !data.length) {
        return
    }
    const flat = flattenObj(data, ['created_at', 'updated_at'])
    const incom = flat.filter(el => (el.isIncome))
    const expens = flat.filter(el => (!el.isIncome))
    // console.log('Cat incom: ', incom, expens)

    return {
        expenses: expens,
        incomes: incom
    }

}


export const resolveDepenses = (data) => {
    // console.log('Resolve Depenses', typeof data, data);
    const flat = flattenObj(data, ['created_at', 'updated_at'])
    flat.map(dep => {
        dep.dateU = moment(dep.date, 'YYYY-MM-DD').format('X')
        return dep
    })
    return JSON.parse(JSON.stringify(flat))
}

export const resolveDepensesParents = (data) => {

    const dataP = resolveDepenses(data)
    let byParent = []
    const mapped = dataP.map(dep => {
        // console.log('DEP', dep)
        const catParent = JSON.parse(JSON.stringify(dep.categorie.parent))
        delete dep.categorie.parent
        dep.catParent = JSON.parse(JSON.stringify(catParent))
        const stock = byParent.find(el => el.id === catParent.id)

        if (!stock) {
            const parent = catParent
            parent.items = [dep]
            byParent.push(parent)
        } else {
            stock.items.push(dep)
        }
        return dep
    })
    byParent = catSort(byParent) 
    return { mapped, byParent }
}

export const getCategFromParentItems = (parent) => {
    if (!parent) {
        return
    }
    const byCateg = []
    parent.items.map(item => {
        const stock = byCateg.find(el => el.id === item.categorie.id)
        if (!stock) {
            const categorie = JSON.parse(JSON.stringify(item.categorie))
            categorie.items = [item]
            byCateg.push(categorie)
        } else {
            stock.items.push(item)
        }
        return item

    })
    return byCateg
}


