import { Switch } from 'antd';
import { useEffect, useState } from 'react';
import { useSpendContext } from './../../hooks/useSpendContext';
export const DepenseType = ({ reset }) => {
    const { dataSpend, dispatch } = useSpendContext()
    const { selected } = dataSpend
    const { switchDepenseType } = selected
    const [reccu, setReccu] = useState(false)
    const [inOut, setInOut] = useState(false)
    const [persoPro, setPersoPro] = useState(false)

    const handleChange = (val) => {
        setReccu(val)
        dispatch({ type: 'DEPENSE_TYPE', payload: val })
    }
    const handleChangeSens = (val) => {
        // console.log(val);
        setInOut(val)
        dispatch({ type: 'SET_IN_OUT', payload: val })
    }
    const handleChangePro = (val) => {
        setPersoPro(val)
        dispatch({ type: 'SET_PERSO_PRO', payload: val })
    }
    useEffect(() => {
        // console.log('switch Reset: ',reset)
        handleChange(false)
        handleChangeSens(false)
        handleChangePro(false)
        // dispatch({ type: 'DEPENSE_TYPE', payload: false })
        // dispatch({ type: 'SET_IN_OUT', payload: false })

    }, [reset])
    return (
        <div className="switchDepense">
            <div className='supSwitch'>
                <div className='swtitle'>
                    {/* in/out: */}
                </div>
                <Switch
                    checkedChildren="Revenu"
                    unCheckedChildren="Dépense"
                    checked={inOut}
                    onClick={handleChangeSens}
                />
            </div>
            <div className='supSwitch'>
                <div className='swtitle'>
                    mouvement:
                </div>
                <Switch
                    checkedChildren="reccurent"
                    unCheckedChildren="normal"
                    onClick={handleChange}
                    checked={reccu}
                />
            </div>
            <div className='supSwitch'>
                <div className='swtitle'>
                    perso/pro:
                </div>
                <Switch
                    checkedChildren="Pro"
                    unCheckedChildren="Perso"
                    checked={persoPro}
                    onClick={handleChangePro}
                />
            </div>
        </div>
    )
}