import { useState } from 'react'
import { useAuthContext } from './useAuthContext'
import axios from 'axios';
import { ApiUrl } from '../services'
// import { sleep } from '../utils';


export const useLogin = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const { dispatch } = useAuthContext()
    let url = ApiUrl()

    const login = async (email, password) => {
        setIsLoading(true)
        setError(null)
        axios
            .post(`${url}/api/auth`, {
                email: email,
                password: password,
            })
            .then(response => {
                console.log('Login response: ',response.data)
                localStorage.setItem('user', JSON.stringify(response.data.data))
                dispatch({ type: 'LOGIN', payload: response.data.data })
                setIsLoading(false)
                window.location.reload()

            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
                setError((error.response) ? ErrorForLogin(error.response.data) : error.message)
                setIsLoading(false)
            });
    }

    return { login, isLoading, error }
}

export const ErrorForLogin= (data ) => {
    if(data.length > 1){
        return data[1]
    }
    return data[0];
}