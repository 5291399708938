import { useAuthContext } from './../../hooks/useAuthContext'
import { useState } from 'react'
import { ReactComponent as HomeBt } from '../../svg/home.svg'
import { useSpendContext } from './../../hooks/useSpendContext';

import { useLogout } from './../../hooks/useLogout'
import './../../scss/navbar.scss'



const NavBar = ({onClick}) => {
    const { logout } = useLogout()
    const [active, setActive] = useState('')
    const { dispatch } = useSpendContext()


    const handleLogoutClick = () => {
        logout()
    }
    const handleClick = (value) => {
        // console.log(value)
        // if(value === 'addSpend'){
        //     dispatch({type:'CLEAR_AND_LOAD', payload: 'all'})
        // }
        setActive(value)
        onClick(value)
    }
    let { user } = useAuthContext()
    user = user.user
    return (
        <nav>
            <div onClick={() => handleClick('')} className={(active === '') ? 'homebt active' : 'homebt'}><HomeBt/></div>
            {/* <button onClick={() => handleClick('addSpend')} className={(active === 'addSpend') ? 'nbutton active' : 'nbutton'}><span>Ajouter une dépense</span></button> */}
            <button onClick={() => handleClick('expenses')} className={(active === 'expenses') ? 'nbutton active' : 'nbutton'}><span>Dépenses/Revenus</span></button>
            <button onClick={() => handleClick('billing')} className={(active === 'billing') ? 'nbutton active' : 'nbutton'}><span>Facturation</span></button>
            <button onClick={() => handleClick('categories')} className={(active === 'categories') ? 'nbutton active' : 'nbutton'}><span>Catégories</span></button>
            {/* <button onClick={() => handleClick('test')} className={(active === 'test') ? 'nbutton active' : 'nbutton'}><span>test Bouton</span></button> */}
            {/* <button onClick={() => handleClick('trade')} className={(active === 'trade') ? 'nbutton active' : 'nbutton'}><span>Trades</span></button> */}
            <div className='user'>
                <small>{user.name}</small>
                <button className='btn logout' onClick={handleLogoutClick}><span>Log out</span></button>
            </div>

        </nav>
    )
}

export default NavBar