import { useEffect, useState } from "react"
import { useSpendContext } from "../../../hooks/useSpendContext"
import { useGetInitial } from "../../../hooks/useGetInitial"
import { usePost } from "../../../hooks/usePost"
import Loading from "../../Loading"
import { SelectCategorie } from "../SelectCategorie"
import { SendOutlined } from "@ant-design/icons"
import { Form, Button, Checkbox } from "antd"
import { DepenseType } from "../DepenseType"
import { SelectDate } from "../SelectDate"
import { Montant } from "../Montant"
import { SelectLieu } from "../SelectLieu"
import { sleep } from "../../../utils"

const validator = (inputs) => {
    if (inputs.amount && inputs.subCateg) {
        return true
    } else {
        return false
    }
}


export const FormAddSpend = ({ onClose }) => {
    const { dataSpend, dispatch } = useSpendContext()
    const { getInitial, response, isLoading: loading } = useGetInitial()
    const { Post, response: postResponse } = usePost()
    const [validForm, setValidForm] = useState(false)
    const [keep, setKeep] = useState(true)
    const [visible, setVisible] = useState(true)


    useEffect(() => {
        getInitial()
    }, [])

    useEffect(() => {
        // console.log('USE EFFECT FOR SELECTED IN FORM', dataSpend.selected)
        setValidForm(validator(dataSpend.selected))
    }, [dataSpend.selected])

    const handleKeep = () => {
        const kp = JSON.parse(JSON.stringify(keep))
        const nkeep = !kp
        setKeep(nkeep)
        dispatch({ type: 'SET_KEEP_FORM', payload: nkeep })
    }

    const handleFinish = async () => {
        const val = JSON.parse(JSON.stringify(dataSpend.selected))
        val.categorie = val.categorie.id
        val.subCateg = val.subCateg.id
        // console.log(JSON.stringify(val, 0, 1))
        let path = (val?.depenseType) ? 'recurrent_expense' : 'expenses'
        await Post(path, val)


    }
    useEffect(() => {
        if (postResponse) {
            const attend = async () => {
                setVisible(false)
                dispatch({ type: 'REFRESH_EXPENSES', payload: postResponse.expenses })
                // console.log('noshow')
                await sleep(10)
                // console.log('show')
                setVisible(true)
            }
            // Form show on/off
            if (!keep) {
                onClose()
            } else {
                attend().catch(console.rror)
            }
            // clean context
            dispatch({ type: 'CLEAR_FORM_ADDSPEND' })
        }


    }, [postResponse])
    return (<>
        {loading && <Loading />}
        {(!loading && visible) && <>
            <Form onFinish={handleFinish}>
                <Form.Item>
                    <DepenseType />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item
                        style={{
                            display: 'inline-block',
                            width: 'calc(48% )',
                        }}>
                        <Montant />
                    </Form.Item>
                    <Form.Item
                        style={{
                            display: 'inline-block',
                            width: 'calc(48% )',
                        }}>
                        {/* <Montant /> */}
                        <SelectDate />
                    </Form.Item>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} >
                    <Form.Item
                        style={{
                            display: 'inline-block',
                            width: 'calc(48% )',
                        }}
                    >
                        <SelectCategorie />
                    </Form.Item>

                    <Form.Item
                        style={{
                            display: 'inline-block',
                            width: 'calc(48% )',
                        }}
                    >
                        <SelectLieu />
                    </Form.Item>


                </Form.Item>
                <Form.Item >
                    <Form.Item label="garder la fenetre ouverte"
                        style={{
                            display: 'inline-block',
                            width: 'calc(48% )',
                        }}>
                        <Checkbox checked={keep} onChange={handleKeep} />
                    </Form.Item>
                    <Form.Item style={{
                        display: 'inline-block',
                        width: 'calc(48% )',
                    }}>
                        <Button htmlType='submit'
                            disabled={(!validForm)}>Ajouter <SendOutlined /></Button>
                    </Form.Item>
                </Form.Item>

            </Form>
            {/* <pre>
                <code>
                    {JSON.stringify(dataSpend.selected, 0, 1)}
                </code>
                <code>
                    {JSON.stringify(postResponse, 0, 1)}
                </code>
            </pre> */}
        </>
        }

    </>)
}

export default FormAddSpend