import { useSpendContext } from "../../../hooks/useSpendContext"
import { Drawer, Switch, Tooltip } from "antd"
import { useMemo, useState } from "react"
import { useEffect, useRef } from "react"
import MaterialReactTable from 'material-react-table';
import { useDeleteExpense } from "../../../hooks/useDeleteExpense";
import { nbrCurrency, Moment } from '../../../utils'
import { DeleteTwoTone, WarningTwoTone, SketchOutlined, ClockCircleOutlined } from '@ant-design/icons'
import { Button, Popconfirm } from 'antd'
import DrawerEditReccu from "../Forms/DrawerEditReccurent";

export const TableExpenses = () => {
    const { deleteExpense, isLoading: delLoading, response } = useDeleteExpense()
    const { dataSpend } = useSpendContext()
    const [viewMode, setViewMode] = useState(true)
    const [data, setData] = useState([])
    const [editReccu, setEditReccu] = useState(null)
    // virtualisation
    const rowVirtualizerInstanceRef = useRef(null)
    const [sorting, setSorting] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const columns = useMemo(
        () => [
            {
                accessorKey: 'date',
                header: 'Date',
                Cell: ({ cell }) => Moment(cell.getValue()).format('L')
            },
            {
                accessorKey: 'categorie.parent.title',
                header: 'Categorie',
            },
            {
                accessorKey: 'categorie.title',
                header: 'Sous Categorie',
            },

            {
                accessorKey: 'amount',
                header: 'Montant',
                muiTableHeadCellProps:{
                    align: 'right'
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                Cell: ({ cell, row }) => <span className="price">{nbrCurrency(cell.getValue(), row.original.currency)}</span>,
            },
            {
                header: 'info',
                muiTableHeadCellProps:{
                    align: 'right',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                size:'94px',
                Cell: ({ row }) => <span className="icoPro">
                    {row.original.invoiceId ? <code>{row.original.invoiceId}</code> : ''}
                    {(row.original.isPro) ? <Tooltip title="Pro" color="#ba97eb"><SketchOutlined /> </Tooltip> : ''}
                    {(row.original.reccurent_expense_id) ? <Tooltip title="Reccurent" color="#ba97eb"><ClockCircleOutlined onClick={() => { handleEditReccu(row) }} /></Tooltip> : ''}
                </span>
            },
            {
                header: 'Action',
                Cell: ({ row }) => <>
                    {(!row.original.reccurent_expense_id) ?
                        <Popconfirm
                            title={`Supprimer cette ${(!viewMode) ? 'dépense' : 'entrée'}`}
                            placement="left"
                            description="es tu sûr ?"
                            onConfirm={() => handleClick(row.original.id)}
                            icon={<WarningTwoTone twoToneColor="#ff1493" />}
                            // onCancel={cancel}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button danger shape="circle" icon={<DeleteTwoTone twoToneColor={'#ff1493'} />} />
                            {/* <a href="#">Delete</a> */}
                        </Popconfirm>
                        :
                        <Button disabled shape='circle'>
                            <DeleteTwoTone twoToneColor={'#cdcdcd'} />
                        </Button>
                    }
                </>,
                muiTableBodyCellProps: {
                    align: 'right',
                },
                muiTableHeadCellProps: {
                    align: 'right',
                }
            }
        ],
        [],
    );
    useEffect(() => {
        const dataToShow = (!viewMode) ? dataSpend.incomes : dataSpend.expenses
        setData(dataToShow)
        setIsLoading(false)
    }, [viewMode])
    useEffect(() => {
        // console.log(dataSpend.incomes.length, dataSpend.expenses.length)
        const dataToShow = (!viewMode) ? dataSpend.incomes : dataSpend.expenses
        setData(dataToShow)
    }, [dataSpend.incomes, dataSpend.expenses])

    useEffect(() => {
        //scroll to the top of the table when the sorting changes
        rowVirtualizerInstanceRef.current?.scrollToIndex(0);
    }, [sorting]);

    const handleClick = async (id) => {
        // await sleep(400)
        // console.log(id)
        await deleteExpense(id)
    }

    const handleEditReccu = (row) => {
        setEditReccu(row.original)
        console.log('Edit Reccurent', row.original)
    }
    const handleCloseEditReccu = () => {
        setEditReccu(null)
    }
    return (<>
        <h3 style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>Detail des
            <Switch
                style={{ marginLeft: '1rem' }}
                checkedChildren="Revenus"
                unCheckedChildren="Dépenses"
                onChange={() => setViewMode(!viewMode)}
            />
        </h3>
        <div className={(viewMode) ? 'negatif' : 'positif'}>
            <MaterialReactTable
                initialState={
                    {
                        density: 'compact',
                        // pagination: { pageSize: 100 }
                    }

                }
                layoutMode="grid"
                enablePagination={false}

                // enableColumnResizing
                muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                sortDescFirst={false}
                columns={columns}
                data={data}
                // virtualisation
                state={{ isLoading, sorting }}
                enableBottomToolbar={false}
                enableColumnVirtualization
                enableGlobalFilterModes
                onSortingChange={setSorting}
                enableRowVirtualization
                rowVirtualizerInstanceRef={rowVirtualizerInstanceRef}
                rowVirtualizerProps={{ overscan: 15 }}
                columnVirtualizerProps={{ overscan: 2 }}

            />
        </div>
        <DrawerEditReccu reccurent={editReccu} onClose={handleCloseEditReccu} />
    </>)
}

export default TableExpenses

