import { useFacturationContext } from '../../../hooks/useFacturationContext';
import { useState, useEffect } from 'react';
import { Button, Drawer } from 'antd';
import { EyeInvisibleTwoTone, DownOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons';
import { motion, AnimatePresence } from "framer-motion"
import FormCustomer from '../FormCustomer';



const Customers = () => {
    const { dataFactu } = useFacturationContext()
    const [open, setOpen] = useState(false)
    const { customers } = dataFactu
    const doEditor = () => {
        setOpen(!open)
    }
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])
    return (
        <div>
            <h4>Patients
                <Button className="hButton" shape="circle" onClick={() => doEditor()}><PlusOutlined /></Button>
            </h4>
            {(customers && customers.length)
                ? <div className='Customers'>
                    {customers.map((cust, i) => <OneCustomer key={i} customer={cust} />)}
                </div>
                : 'PAS DE CUSTOMERS CONNUS'
            }
            <Drawer
                title="Créer un patient"
                open={open}
                onClose={() => setOpen(false)}
            >
                {open && <FormCustomer onFinish={() => setOpen(false)}/>}
            </Drawer>

            <pre><code>
                {JSON.stringify(customers, 0, 2)}
            </code></pre>
        </div>
    )
}
const variants = {
    open: { opacity: 1, x: 0, rotate: 0 },
    closed: { opacity: 1, x: "-100%", rotate: 360 },
}
const OneCustomer = ({ customer }) => {
    const [fold, setFold] = useState(false)

    return <div className="oneCustomer">
        <div className="header">
            <div>{customer.patient} {!customer.visible ? <EyeInvisibleTwoTone /> : ''}</div>
            <div className='resumeValue'>
                <motion.span
                    onClick={() => setFold(!fold)}
                    animate={fold ? "open" : "closed"}
                    duration={0.5}
                    variants={variants}
                    style={{ cursor: 'pointer' }}

                >{fold ? <DownOutlined style={{ color: '#AA0000', fontSize: '1.1em', fontWeight: 'bold' }} /> : <LeftOutlined />}
                </motion.span>
            </div>
        </div>
        <div className="subHeader">
            {customer.companyName ? <><small><b>{customer.companyName}</b></small><br /></> : ''}
            <small>{customer.address}</small><br />
            <small>{customer.postalCode} {customer.city}</small><br />
        </div>



        <AnimatePresence>
            {fold && <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "150%" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: .32 }}
            // style={{ borderTop: '1px solid #cdcdcd', marginTop: '0.5rem' }}
            >
                <FormCustomer customerInfo={customer} onFinish={()=>setFold(!fold)}/>

            </motion.div>}
        </AnimatePresence>
    </div>
}




export default Customers