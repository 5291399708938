import '../scss/logged.scss'
// import { AddSpend } from "../components/AddSpend";
import NavBar from "../components/Logged/navbar";
// import TestMutation from '../components/Logged/testMutation';
// import { Trade } from '../components/Trade';
import { Notification } from '../components/Notif';
import { HomeLogged } from '../components/Logged/HomeLogged';
import { useState } from 'react';
import { Expenses } from '../components/Logged/Expenses';
import { Categories } from '../components/Logged/Categories';
import Facturation from '../components/Logged/FacturationHome';
import TestMultiple from '../components/Logged/Forms/testMultiple';
import { OnOff } from '../components/Logged/OnOffLine';

export default function Logged() {
    const [navigation, setNavigation] = useState('')
    return (
        <div className='loggedPage' >
            <NavBar onClick={(nav) => { setNavigation(nav) }} />
            <div style={{ minHeight: '100vh' }}>
                {(navigation === 'expenses') ? <Expenses /> : <></>}
                {(navigation === 'billing') ? <Facturation /> : <></>}
                {(navigation === 'categories') ? <Categories /> : <></>}
                {(navigation === 'test') ? <TestMultiple /> : <></>}
                {/* {(navigation === 'trade') ? <Trade /> : ''} */}
                {(navigation === '') ? <HomeLogged /> : <></>}

            </div>
            <Notification />
            <OnOff/>
        </div>
    )
}

