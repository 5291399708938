import { useState } from 'react'
import { useSpendContext } from './useSpendContext';
import { ApiUrl } from '../services'
import { axiosInstance as axios } from '../AxiosToken';
import { manageErrorPigApi, sleep } from '../utils';

export const useGet = () => {
  const { dispatch } = useSpendContext()
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  const Get = async (path, filter) => {
    setIsLoading(true)
    setError(null)
    let url = ApiUrl()
    // let path = 'expenses'

    try {
      if (filter) {
        path += `?${filter}`
      }
      let lnk = `${url}/api/${path}`
      if (process.env.NODE_ENV !== 'production') {
        const sleepDuration = 300
        console.group('get Request')
        console.log('useGet: ', lnk);
        // console.log('data: ', JSON.stringify(data, 0, 1));
        // console.table(data)
        await sleep(sleepDuration)
        console.warn(`SLEEP ${sleepDuration}ms Enabled in useGet.jsx`)
        console.groupEnd()
      }
      const respAxios = await axios.get(lnk)
      let result = respAxios.data
      // console.log('getExpenses Responses Axios', result)
      setResponse(result)
      setIsLoading(false)
      if (result.message) {
        dispatch({
          type: 'SET_NOTIF', payload: {
            message: result.message,
            type: 'success'
          }
        })
      }
    } catch (error) {
      let errorMessage = manageErrorPigApi(error)
      setError(error)
      dispatch({
        type: 'SET_NOTIF', payload: {
          message: (errorMessage) ? errorMessage : error.message,
          type: 'error'
        }
      })
      setIsLoading(false)
    }
  }
  return { Get, isLoading, error, response }
}


