import { useState } from 'react'
import { useSpendContext } from './useSpendContext';
import { ApiUrl } from '../services'
import { axiosInstance as axios } from '../AxiosToken';
import { manageErrorPigApi, sleep } from '../utils';

export const useAxios = () => {
  const { dispatch } = useSpendContext()
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  const sendAxios = async (path) => {
    setIsLoading(true)
    setError(null)
    setResponse(null)
    let url = ApiUrl()

    try {
      let lnk = `${url}/api/${path}`
      // console.log(lnk);
      if (process.env.NODE_ENV !== 'production') {
        const sleepDuration = 75
        console.group('Axios GET Request')
        console.log('useAxios: ', lnk);
        await sleep(sleepDuration)
        console.warn(`SLEEP ${sleepDuration}ms Enabled in useAxios.jsx`)
        console.groupEnd()
      }
      const respAxios = await axios.get(lnk)
      let result = respAxios.data
      // console.log(result)
      setResponse(result)
      setIsLoading(false)

    } catch (error) {
      let errorMessage = manageErrorPigApi(error)
      setError(error)
      console.log(error)
      dispatch({
        type: 'SET_NOTIF', payload: {
          message: (errorMessage) ? errorMessage : error.message,
          type: 'error'
        }
      })
      setIsLoading(false)
    }
  }
  return { sendAxios, isLoading, error, response }
}

// const manageErrorStrapi = (error) => {

//   if (error.response) {
//     // response de strapi
//     // contient data, qui contient la reponse envoyé
//     let strapiMessage = (error.response?.data?.error?.details?.error)
//       ? error.response.data.error.details.error
//       : false
//     if (typeof strapiMessage === 'string') {
//       return strapiMessage
//     }
//   }
//   if (error?.request?.statusText) {
//     return error.request.statusText + ' / ' + error.request.status
//   }
//   return false
// }

