import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthContextProvider } from './Context/AuthContext';
import { SpendContextProvider } from './Context/SpendContext';
import { FacturationContextProvider } from './Context/FacturationContext';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ApiUrl } from './services';
import { HomeContextProvider } from './Context/HomeContext';
const url = ApiUrl()
const httpLink = createHttpLink({
  uri: `${url}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const storage = localStorage.getItem('user');
  const storVal = (storage) ? JSON.parse(storage) : null
  const token = (storVal) ? storVal.jwt : null
  // return the headers to the context so httpLink can read them
  // console.log(token, storage)

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    }
  }
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // uri: 'http://localhost:1337/graphql',
  cache: new InMemoryCache(),
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <AuthContextProvider>
        <HomeContextProvider>
          <SpendContextProvider>
            <FacturationContextProvider>
            <App />
            </FacturationContextProvider>
          </SpendContextProvider>
        </HomeContextProvider>
      </AuthContextProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
