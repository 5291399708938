import './App.scss';
import { usePrefSheme } from './use-preferSheme'
import { useAuthContext } from './hooks/useAuthContext'
import Login from './components/Login';
import Logged from './pages/logged';


function App() {

  const navPrefMode = usePrefSheme()
  const { user } = useAuthContext()
  if (!user) {
    return (
      <div className='App'>
        <Login />
      </div>
    )
  }

  return (
    <div className=''>
      <Logged />
    </div>
  );
}

export default App;



