import { createContext, useReducer, useEffect } from 'react'
import { resolveCategories, resolveLieux } from '../resolvers';
import { catSort, sleep } from '../utils';

export const SpendContext = createContext()

export const spendReducer = (state, action) => {
  switch (action.type) {
    case 'SET_CATEG':
      return { dataSpend: setCateg(state, action.payload) }
    case 'UPDATE_ONE_CATEGORIE':
      return { dataSpend: updateOneCategorie(state, action.payload) }
    case 'UPDATE_SUBCAT_PARENT':
      return { dataSpend: updateSubCatParent(state, action.payload) }
    // case 'RESPONSE_CREATE_EXPENSE':
    //   return { dataSpend: responseCreateExpense(state, action.payload) }
    case 'SET_LIEUX':
      return { dataSpend: setLieux(state, action.payload) }
    case 'SET_DATA':
      return { dataSpend: setData(state, action.payload) }
    case 'SET_ERROR':
      return { dataSpend: setError(state, action.payload) }
    case 'SET_SELECT_LIEU':
      return { dataSpend: setSelectLieu(state, action.payload) }
    case 'SET_SELECT_CAT':
      return { dataSpend: setSelectCat(state, action.payload) }
    case 'SET_SELECT_SUBCAT':
      return { dataSpend: setSelectSubCat(state, action.payload) }
    case 'SET_SELECT_DATE':
      return { dataSpend: setSelectDate(state, action.payload) }
    case 'SET_AMOUNT':
      return { dataSpend: setAmount(state, action.payload) }
    case 'CLEAR_AND_LOAD':
    case 'CLEAR_FORM_ADDSPEND':
      return { dataSpend: clearAndLoad(state, action.payload) }
    case 'SET_IN_OUT':
      return { dataSpend: setInOut(state, action.payload) }
    case 'SET_PERSO_PRO':
      return { dataSpend: setPersoPro(state, action.payload) }
    case 'SET_KEEP_FORM':
      return { dataSpend: setKeepForm(state, action.payload) }

    case 'DEPENSE_TYPE':
      return { dataSpend: setDepenseType(state, action.payload) }
    case 'SET_RECCU_PAYMNT':
      return { dataSpend: setDepenseReccu(state, action.payload) }
    // notifs 
    case 'SET_NOTIF':
      return { dataSpend: setNotif(state, action.payload) }
    case 'CLEAR_NOTIF':
      return { dataSpend: clearNotif(state, action.payload) }
    case 'CLEAR_TIMEOUT':
      return { dataSpend: clearTimeOut(state, action.payload) }


    // expenses

    case 'SET_DATA_EXPENSES':
      return { dataSpend: setExpensesData(state, action.payload) }

    case 'REFRESH_EXPENSES':
      return { dataSpend: refreshExpenses(state, action.payload) }


    case 'CLEAR':
      return { dataSpend: dataSpendDefault }
    default:
      return state
  }
}
const dataSpendDefault = {
  selected: {},
  notif: {}
}

const setCateg = (state, data) => {
  const ds = state.dataSpend
  ds.dataCateg = data
  return ds
}
const setLieux = ({dataSpend}, data) => {
  const lieux = resolveLieux(data)
  dataSpend.lieux = lieux
  return dataSpend
}

const setError = (state, data) => {
  const { dataSpend } = state
  dataSpend.error = data
  return dataSpend
}

const setSelectLieu = ({ dataSpend }, lieu) => {
  dataSpend.selected = { ...dataSpend.selected, lieu }
  return dataSpend
}
const setSelectCat = ({ dataSpend }, categorie) => {
  dataSpend.selected = { ...dataSpend.selected, categorie }
  return dataSpend
}
const setSelectSubCat = ({ dataSpend }, subCateg) => {
  dataSpend.selected = { ...dataSpend.selected, subCateg }
  return dataSpend
}
const setSelectDate = ({ dataSpend }, date) => {
  dataSpend.selected = { ...dataSpend.selected, date }
  return dataSpend
}
const setAmount = ({ dataSpend }, amount) => {
  dataSpend.selected = { ...dataSpend.selected, amount }
  return dataSpend
}

// const responseCreateExpense = ({dataSpend}, newExpense) => {
//   console.log('SpendContext, Response new Expense: ',newExpense.data);
//   dataSpend.expenses.push(newExpense.data)
//   console.log(dataSpend.expenses)

//   return dataSpend
// }
const setDepenseType = ({ dataSpend }, depenseType) => {
  dataSpend.selected = { ...dataSpend.selected, depenseType }
  return dataSpend
}

const setInOut = ({ dataSpend }, inout) => {
  dataSpend.selected = { ...dataSpend.selected, inout }
  dataSpend.categories = (inout) ? dataSpend.categIncome : dataSpend.categExpenses
  return dataSpend
}

const setPersoPro = ({ dataSpend }, persoPro) => {
  dataSpend.selected = { ...dataSpend.selected, persoPro }
  return dataSpend

}

const setKeepForm = ({ dataSpend }, keepForm) => {
  dataSpend.selected = { ...dataSpend.selected, keepForm }
  return dataSpend
}

const setDepenseReccu = ({ dataSpend }, reccurence) => {
  dataSpend.selected = { ...dataSpend.selected, reccurence }
  return dataSpend
}
const setNotif = ({ dataSpend }, notif) => {
  dataSpend.notif = notif
  return dataSpend
}
const clearNotif = ({ dataSpend }) => {
  dataSpend.notif = {}
  return dataSpend
}
const clearTimeOut = ({ dataSpend }, id) => {
  dataSpend.notif = { id: id }
  return dataSpend
}

const clearAndLoad = ({ dataSpend }, value) => {
  dataSpend.selected = {}
  return dataSpend
}


const setData = ({ dataSpend }, data) => {
  // reception des data initial de l api, traitement
  // console.log('Spend context data: ', data);
  const lieux = resolveLieux(data.lieux)
  const categories = resolveCategories(data.categories)
  // console.log('dataSpend?.selected?.inout', dataSpend?.selected?.inout)
  const expenses = catSort(categories.expenses)
  const incomes = catSort(categories.incomes)
  dataSpend.selected = {}
  dataSpend.categories = expenses
  dataSpend.categIncome = incomes
  dataSpend.categExpenses = expenses
  dataSpend.lieux = lieux
  return dataSpend
}

const setExpensesData = ({ dataSpend }, data) => {
  let result = data.result
  let incomes = data.incomes
  let filters = data.filters
  dataSpend.expenses = result
  dataSpend.incomes = incomes
  return dataSpend
}

const refreshExpenses = ({ dataSpend }, values) => {

  // console.log('SpendContext Refresh Expenses:',values)
  dataSpend.expenses = values.outcomes
  dataSpend.incomes = values.incomes
  return dataSpend
}

const updateOneCategorie = ({ dataSpend }, data) => {
  let child = null
  let parent = null
  if (data.trigger === 'newTitle' || data.trigger === 'newParent') {
    const categories = resolveCategories(data.val)
    dataSpend.categories = catSort(categories.expenses)
    dataSpend.categIncome = categories.incomes
  }

  return dataSpend
}

const updateSubCatParent = ({ dataSpend }, data) => {
  const OldParent = dataSpend.categories.find(cat => cat.id === data.originParent)
  const nwParent = dataSpend.categories.find(cat => cat.id === data.newParent)
  let itm = OldParent.childrens.find(subCat => subCat.id === data.idSubCat)
  // console.log(itm)
  // console.log('old Parent: ', OldParent, 'new Parent: ', nwParent, 'item', itm);
  const item = JSON.parse(JSON.stringify(itm))
  itm = null
  item.parent_id = data.newParent
  nwParent.childrens.push(item)
  return dataSpend
}




export const SpendContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(spendReducer, {
    dataSpend: dataSpendDefault,
  })
  useEffect(()=>{
    console.log('USE EFFECT IN CONTEXT SPEND')
    localStorage.setItem("dataSpend", JSON.stringify(state.dataSpend));

  },[state])

  return (
    <SpendContext.Provider value={{ ...state, dispatch }}>
      {children}
    </SpendContext.Provider>
  )

}