import { Button, DatePicker, Drawer, Input, Select, Form, InputNumber, Space } from "antd"
import { PlusOutlined, SendOutlined, MinusCircleOutlined } from "@ant-design/icons"
import { useEffect, useState } from "react"
import { useFacturationContext } from "../../../hooks/useFacturationContext"
import { useSpendContext } from "../../../hooks/useSpendContext"
import { useGetSeance } from "../../../hooks/useGetSeance"
import { usePost } from "../../../hooks/usePost"
import { usePut } from "../../../hooks/usePut"
import qs from 'qs'
import { Moment, sleep } from "../../../utils"
import dayjs from "dayjs"
import locale from 'antd/es/date-picker/locale/fr_FR'
import { isOnline, offlineStorage } from "../../../offLine"

const defaultSeance = {
    customerId: null,
    date: dayjs(),
    price: 80,
    name: null
}
const baseIn = [
    {
        price: '80',
        date: dayjs()
    }
]

export const DrawerAddSeance = () => {
    const [open, setOpen] = useState(false)
    const { response: getResponse, Get } = useGetSeance()
    const { Post, response: postResponse } = usePost()
    const { Put, response: putResponse } = usePut()
    const [loading] = useState(false)
    const [valToSend, setValToSend] = useState({})
    const { dispatch: dispatchMess } = useSpendContext()
    const { dataFactu, dispatch } = useFacturationContext()
    const { editedSeance } = dataFactu
    const { customers } = dataFactu
    const doNothing = () => { }
    const doEditor = () => {
        if (editedSeance) {
            setValToSend(editedSeance)
        } else {
            setValToSend(defaultSeance)
        }
        setOpen(true)
    }
    const doGetUsers = async () => {
        const query = qs.stringify({
            navigation: 'customers'
        }, {
            encodeValuesOnly: true,
        });

        let lnk = `facturation?${query}`
        await Get(lnk)
    }
    const doClose = () => {
        if (editedSeance) {
            dispatch({ type: 'RESET_EDITED_SEANCE' })
        }
        setOpen(false)
    }
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    useEffect(() => {
        (editedSeance) ? doEditor() : doNothing()
    }, [editedSeance])

    useEffect(() => {
        if (!customers) {
            doGetUsers()
        }
    }, [editedSeance])

    useEffect(() => {
        if (getResponse && getResponse.result) {
            dispatch({ type: 'SET_DATA', payload: getResponse })
        }
    }, [getResponse]);

    useEffect(() => {
        if (postResponse && postResponse.result) {
            dispatch({ type: 'SET_PRODUCTS', payload: postResponse.products })
            console.log('DRAWER ADD SEANCE: ', postResponse)
            if (postResponse.invoicesFuture) {
                // console.log('invoice future detecetect')
                dispatch({ type: 'UPDATE_INVOICES_FUTURE', payload: postResponse.invoicesFuture })
            }
            doClose()
        }
    }, [postResponse]);

    useEffect(() => {
        if (putResponse && putResponse.result) {
            dispatch({ type: 'SET_PRODUCTS', payload: putResponse.products })
            doClose()
        }
    }, [putResponse]);


    const handleChangeCustomer = (value) => {
        const v2s = JSON.parse(JSON.stringify(valToSend))
        v2s.customerId = value
        setValToSend(v2s)
    }
    const handleSelectDate = (date) => {
        const v2s = JSON.parse(JSON.stringify(valToSend))
        v2s.date = date
        setValToSend(v2s)
    }

    const handleChangeLibelle = (txt) => {
        const v2s = JSON.parse(JSON.stringify(valToSend))
        v2s.name = txt
        setValToSend(v2s)
    }
    const handleChangeAmount = (amount) => {
        const v2s = JSON.parse(JSON.stringify(valToSend))
        v2s.price = amount
        setValToSend(v2s)
    }
    const onFinish = async (values) => {
        const vts = JSON.parse(JSON.stringify(valToSend));

        if (!vts.date) {
            vts.date = Moment()
        } else {
            vts.date = Moment(vts.date)
        }

        let path = `products`
        if (editedSeance) {
            if (!vts.name) {
                vts.name = 'Le ' + vts.date.format('ll')
            }
            path += `/${vts.id}`
            vts.date = vts.date.format('YYYY-MM-DD')
            await Put(path, vts)
        } else {
            // console.clear()

            values.products.map(prod => {
                const d = Moment(prod.date.format('YYYY-MM-DD'))
                // console.log( prod.date.format('YYYY-MM-DD'))
                prod.customerId = vts.customerId
                prod.name = (vts.name) ? vts.name : `Le ${d.format('ll')}`
                prod.dateDb = d.format('YYYY-MM-DD')

                return prod

            })

            if (isOnline()) {
                await Post(path, values.products)
            } else {
                offlineStorage('addSeance', 'POST', path, values.products)
                dispatch({ type: 'OFFLINE_ADD_SEANCE', payload: values.products })
                dispatchMess({
                    type: 'SET_NOTIF', payload: {
                        message: 'Mode hors ligne!',
                        type: 'danger'
                    }
                })
                doClose()
            }
        }
    }

    return (
        <>
            <Button className="hButton" shape="circle" onClick={() => doEditor()}><PlusOutlined /></Button>
            {(open && customers) &&
                <Drawer
                    width={600}
                    title={editedSeance ? 'Modifier une Séance' : 'Ajouter une Séance'}
                    open={open}
                    onClose={() => doClose()}

                >
                    {!loading ? <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 20 }}
                        style={{ maxWidth: 500, margin: '0 auto' }}
                        onFinish={onFinish}
                        name="pourRire"


                    >
                        <Form.Item
                            label="Patient"
                        >
                            <SelectCustomer
                                customers={customers}
                                edited={editedSeance}
                                onChange={handleChangeCustomer}
                            />
                        </Form.Item>

                        {!editedSeance
                            ? <Form.Item label="Plop">
                                <Form.List name="products" initialValue={baseIn} >
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Space
                                                    key={key}
                                                    style={{
                                                        display: 'flex',
                                                        marginBottom: "5px",
                                                        alignItems: 'center'
                                                    }}
                                                    align="baseline"
                                                >
                                                    <Form.Item
                                                        {...restField}
                                                        initialValue="80"
                                                        style={{ margin: 0 }}
                                                        name={[name, 'price']}
                                                    >
                                                        <InputNumber
                                                            placeholder="80.00"
                                                            addonAfter=" CHF"
                                                            style={{
                                                                width: '150px'
                                                            }}
                                                            formatter={(value) => `${value}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}
                                                            parser={(value) => value.replace(/\s?|(,*)/g, '')}

                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        style={{ margin: 0 }}
                                                        name={[name, 'date']}
                                                        rules={[{ required: true, message: '' }]}

                                                    >
                                                        <DatePicker
                                                            locale={locale}
                                                            format="DD/MM/YYYY"
                                                        />
                                                    </Form.Item>
                                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                                </Space>
                                            ))}
                                            <Form.Item label="Ajouter une Séance">
                                                <Button shape="circle" onClick={() => add()} icon={<PlusOutlined />} />
                                            </Form.Item>
                                        </>
                                    )}
                                </Form.List>
                            </Form.Item>
                            : <Form.Item
                                style={{ marginBottom: 0 }}
                                label="date">
                                <Form.Item
                                    // label='Date'
                                    style={{
                                        display: 'inline-block',
                                        width: 'calc(50% )',
                                    }}
                                    wrapperCol={{
                                        // offset: 3,
                                        span: 22,
                                    }}
                                >
                                    <SelectDate
                                        edited={editedSeance}
                                        onChange={handleSelectDate}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='Montant'

                                    style={{
                                        display: 'inline-block',
                                        width: 'calc(50% )',
                                    }}
                                >
                                    <Montant
                                        edited={editedSeance}
                                        onChange={handleChangeAmount}
                                    />
                                </Form.Item>

                            </Form.Item>}






                        <Form.Item
                            label='Libellé'
                        >
                            <Libelle
                                edited={editedSeance}
                                onChange={handleChangeLibelle}
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button htmlType='submit'
                                disabled={(!valToSend.customerId)}>{editedSeance ? 'Modifier la Séance' : 'Creer une Séance'}<SendOutlined /></Button>
                        </Form.Item>


                    </Form> : <div>my form must be hidden</div>
                    }
                    {/* <hr />
                    <h4>getLoading {(getLoading) ? 'true' : 'false'}</h4>
                    <h4>Loading {(loading) ? 'true' : 'false'}</h4>
                    <h4>valToSend</h4>
                    {JSON.stringify(valToSend, 0, 2)}
                    <h4>Edited seance ?</h4>
                    {JSON.stringify(editedSeance, 0, 2)}
                    <h4>Customer ?</h4>
                    {JSON.stringify(customers, 0, 2)} */}


                </Drawer>
            }
            {/* <h4>POST ERROR</h4>
            <>{JSON.stringify(postError)}</>
            <h4>POST RESPONSE</h4>
            <>{JSON.stringify(postResponse)}</> */}
        </>
    )
}

const Montant = ({ edited, onChange }) => {
    const defaultAmount = (edited) ? edited.price : 80
    // onChange(defaultAmount)
    const [amount, setAmount] = useState(defaultAmount)
    const handleChangeAmount = (value) => {
        setAmount(value)
        onChange(value)

    }

    return (
        <>
            <InputNumber
                value={amount}
                // style={{ width: '100%' }}
                // format="DD/MM/YYYY"
                onChange={(e) => handleChangeAmount(e)}
                step="0.01"
            // stringMode
            />
        </>
    )
}

const Libelle = ({ edited, onChange }) => {

    const defaultTexte = (edited) ? edited.name : ''
    // if(defaultTexte !== ''){
    //     onChange(defaultTexte)
    // }
    const [texte, setTexte] = useState(defaultTexte)
    const handleChangeText = (e) => {
        const v = e.target.value
        // console.log(v)
        setTexte(v)
        onChange(v)

    }

    return (
        <>
            <Input
                value={texte}
                // style={{ width: '100%' }}
                // format="DD/MM/YYYY"
                onChange={(txt, n) => handleChangeText(txt, n)}
            />
        </>
    )
}



const SelectDate = ({ edited, onChange }) => {

    const defaultDate = (edited) ? dayjs(edited.date, 'YYYY-MM-DD') : dayjs()
    const [date, setDate] = useState(defaultDate)
    const handleChangeDate = (date, dateString) => {
        // console.log(date, dateString)
        setDate(date)
        onChange(date)

    }

    return (
        <>
            <DatePicker
                value={date}
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                onChange={(date, dateString) => handleChangeDate(date, dateString)}
            />
        </>
    )
}

const SelectCustomer = ({ customers, edited, onChange }) => {

    const [selected, setSelected] = useState(edited?.customerId)
    const custo = customers.filter(c => c.visible === 1)
    const options = custo.map(p => {
        return {
            label: p.patient,
            value: p.id
        }
    })
    const handleChange = (value) => {
        // console.log(value)
        onChange(value)
    }
    return (
        <>
            <Select
                showSearch
                placeholder='Veuillez Choisir un Patient'
                style={{
                    width: '100%',
                }}
                defaultValue={selected}
                bordered={false}
                options={options}
                onChange={handleChange}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
            />
        </>

    )
}


export default DrawerAddSeance