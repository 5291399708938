// verifie les modifications du color sheme du system 

// en cas de cookie  on respect le cookie, sinon on recupere la nouvelle valeur

import { useState, useEffect } from 'react';
// import { Cookies } from "react-cookie-consent";

export function usePrefSheme() {

  const [mode, setMode] = useState('light')

  useEffect(() => {
    // Add listener to update styles
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => onSelectMode(e.matches ? 'dark' : 'light'));

    // Setup dark/light mode for the first time
    onSelectMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

    // Remove listener
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
      });
    }
  }, [mode]);

  const onSelectMode = (mode) => {
    setMode(mode)
    if (mode === 'dark')
      document.body.classList.add('dark-mode')
    else
      document.body.classList.remove('dark-mode')
  }
  return mode;


}

// const [mode, setMode] = useState('light')

//   useEffect(() => {
//     // Add listener to update styles
//     window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => onSelectMode(e.matches ? 'dark' : 'light'));

//     // Setup dark/light mode for the first time
//     onSelectMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

//     // Remove listener
//     return () => {
//       window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
//       });
//     }
//   }, []);

//   const onSelectMode = (mode) => {
//     setMode(mode)
//     if (mode === 'dark')
//       document.body.classList.add('dark-mode')
//     else
//       document.body.classList.remove('dark-mode')
//   }