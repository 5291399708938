import { Button, Form, Space, InputNumber, Input, DatePicker, Select } from "antd"
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons"
import dayjs from "dayjs"
import 'dayjs/locale/fr'
import locale from 'antd/es/date-picker/locale/fr_FR'

const options = [
    { value: 'Burns Bay Road', label: 'test' },
    { value: 'Downing Street' },
    { value: 'Wall Street' },
];

export const TestMultiple = () => {

    const onFinish = (value) => {
        console.log(JSON.stringify(value, 0, 1))
    }
    const baseIn = [
        {
            amount: '80',
            date: dayjs()
        }
    ]

    return (
        <>
            <Form
                name="dynamic_form_nest_item"
                onFinish={onFinish}
                style={{
                    maxWidth: 600,
                    margin: "15rem auto"
                }}
                autoComplete="off"
            >
                <Form.Item label="Patient" name="patient">
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={[
                            {
                                value: '1',
                                label: 'Not Identified',
                            },
                            {
                                value: '2',
                                label: 'Closed',
                            },
                            {
                                value: '3',
                                label: 'Communicated',
                            },
                            {
                                value: '4',
                                label: 'Identified',
                            },
                            {
                                value: '5',
                                label: 'Resolved',
                            },
                            {
                                value: '6',
                                label: 'Cancelled',
                            },
                        ]}
                    />
                </Form.Item>

                <Form.List name="products" initialValue={baseIn} >
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space
                                    key={key}
                                    style={{
                                        display: 'flex',
                                        marginBottom: "5px",
                                        alignItems: 'center'
                                    }}
                                    align="baseline"
                                >
                                    <Form.Item
                                        {...restField}
                                        initialValue="90"
                                        style={{ margin: 0 }}
                                        name={[name, 'amount']}
                                    >
                                        <InputNumber
                                            placeholder="80.00"
                                            addonAfter=" CHF"
                                            style={{
                                                width: '150px'
                                            }}
                                            //  ^(?!0\.00)[1-9]\d{0,2}(,\d{3})*(\.\d\d)?$
                                            formatter={(value) => `${value}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ")}
                                            parser={(value) => value.replace(/\s?|(,*)/g, '')}

                                        />
                                    </Form.Item>
                                    <Form.Item
                                        {...restField}
                                        style={{ margin: 0 }}
                                        name={[name, 'date']}
                                        rules={[{ required: true, message: '' }]}

                                    >
                                        <DatePicker
                                            locale={locale}
                                            format="DD/MM/YYYY"
                                        />
                                    </Form.Item>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item label="nouvelle date">
                                <Button shape="circle" onClick={() => add()} icon={<PlusOutlined />} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}

export default TestMultiple