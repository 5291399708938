import React, { useEffect, useState } from 'react';

import CreatableSelect from 'react-select/creatable';
import { axiosInstance as axios } from '../AxiosToken';
import {useSpendContext} from '../hooks/useSpendContext'

// import { useSpendContext } from "../Context/SpendContext.js"

const createFromResponse = (values) => {
    // console.log('createFromResponse', values.data)
    let id = values.data.id
    let title = values.data.title
    return {
        label: title,
        value: id,
        id,
        title
    }
}
/**
 * It takes a list of objects and returns a list of objects with the same properties but with a label
 * and value property added
 */
const optioneur = (list) => {
    const myList = JSON.parse(JSON.stringify(list))
    if (list) {
        myList.map(el => {
            el.label = el.title
            el.value = el.id
            return el
        })
        return myList
    }
    return []

}
/**
 * It's a React component that renders a select menu with a list of options, and allows the user to
 * create new options
 */
export const SelectMenu = ({ onSelect, list, parent, placeholder, reset }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState();
    const [value, setValue] = useState();
    const [parents, setParents] = useState();
    const { dispatch, dataSpend } = useSpendContext()

    useEffect(() => {
        setOptions(optioneur(list))
        setParents(parent)
        return
    }, [list, parent])

    useEffect(() => {
        if (reset === true) {
            setValue(null)
            // console.log('reset', reset)
        }
        return
    }, [reset])


    const handleCreate = (inputValue) => {
        setIsLoading(true);
        let url = `api/categories`
        let data = {
            title: inputValue,
            parent_id: parents,
            income: (dataSpend.selected?.inout) ? dataSpend.selected.inout : false
        }
        // console.log(data)
        // return 
        axios.post(url, data)
            .then(response => {
                // return  response;
                // console.log(response.data.data)
                const newOption = createFromResponse(response.data);
                setIsLoading(false);
                // console.log(response, newOption)

                setOptions((prev) => [...prev, newOption]);
                handleSetValue(newOption);
                dispatch({
                    type: 'SET_NOTIF', payload: {
                      message: response.data.message,
                      type: 'success'
                    }
                  })            })
            .catch((error) => {
                //return  error;
                console.error(error)
            })
            .then(() => {
                setIsLoading(false);

            })
    };


    const handleSetValue = (newValue) => {
        setValue(newValue)
        onSelect(newValue)
    }

    return (
        <CreatableSelect
            isClearable
            isDisabled={isLoading}
            isLoading={isLoading}
            onChange={(newValue) => handleSetValue(newValue)}
            onCreateOption={handleCreate}
            options={options}
            value={value}
            placeholder={`Choisis/Creer ${placeholder}`}
        />
    );
};