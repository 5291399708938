import axios from 'axios';
import { ApiUrl } from './services'

const getToken = () => {

  const user = localStorage.getItem('user')
  if (user) {
  let { token } = JSON.parse(user)
  // console.log('user, user found', token)
  return token
  }
  console.log('token, user not found')

  return ''
}
const jwt = getToken()

export const axiosInstance = axios.create({
  baseURL: `${ApiUrl()}/`,
  timeout: 5000,
  headers: {
    'Authorization': `Bearer ${jwt}`,
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    // 'Access-Control-Allow-Origin': 'localhost',
    // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
  }
});  
