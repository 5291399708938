import { SpendContext } from '../Context/SpendContext'
import { useContext } from "react"

export const useSpendContext = () => {
  const context = useContext(SpendContext)

  if(!context) {
    throw Error('useSpendContext must be used inside an AuthContextProvider')
  }

  return context
}