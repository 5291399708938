import { useEffect, useState } from "react"
import { SelectMenu } from "../SelectMenu"
import { useSpendContext } from '../../hooks/useSpendContext';
import { catSort, sleep } from "../../utils";



export const SelectCategorie = ({ reset }) => {
    const [catSelect, setCatSelect] = useState()
    const [catList, setCatList] = useState(null)
    const [subCatList, setSubCatList] = useState([])
    const [myReset, setMyReset] = useState(reset)
    const { dataSpend, dispatch } = useSpendContext()
    const { categories } = dataSpend
    const { selected } = dataSpend
    const { inout } = selected


    const handleCatSelect = async (value) => {
        // console.log(`SelectCategorie: `, value)

        setSubCatList(null)
        setCatSelect(null)
        handleSubCatSelect(null)
        dispatch({ type: 'SET_SELECT_CAT', payload: null })
        await sleep(1);
        setMyReset(false)
        if (!value) {
            return
        }
        const val = (value) ? value.id : null
        // const subCat = (val) ? categories.filter(cat => cat.parents === value.id) : []
        const subCat = (val) ? categories.find(el => el.id === val) : []
        const subCatList = (subCat?.childrens.length) ? catSort(subCat.childrens) : []
        // console.log(subCatList)

        setSubCatList(subCatList)
        setCatSelect(val)
        dispatch({ type: 'SET_SELECT_CAT', payload: value })
    }
    const handleSubCatSelect = (value) => {
        // console.log('SUB CATEGORIE SELECTED: ', value)
        dispatch({ type: 'SET_SELECT_SUBCAT', payload: value })
    }

    useEffect(() => {
        if (reset === true) {
            setCatSelect(null)
            setMyReset(reset)

        }

        return
    }, [reset])

    useEffect(() => {
        // console.log('inout', inout)
        dispatch({ type: 'SET_SELECT_CAT', payload: null })
        dispatch({ type: 'SET_SELECT_SUBCAT', payload: null })

        setSubCatList(null)
        setCatSelect(null)
        setMyReset(!myReset)
    }, [inout])

    useEffect(() => {
        const getCategorie = () => {
            if (categories) {
                const listCat = categories.filter(el => !el.parents)
                // console.log(listCat)
                setCatList(listCat)
            }
        }
        return getCategorie()
    }, [categories])
    return (
       
        <>
         <div className="categorie">
            <h4>Categorie</h4>
            <div className="selectMenu">
                <SelectMenu
                    onSelect={(v) => handleCatSelect(v)}
                    list={catList}
                    placeholder='une Categorie'
                    reset={myReset}
                />
            </div>
            {catSelect &&

                <div className="selectMenu">
                    <SelectMenu
                        onSelect={(v) => handleSubCatSelect(v)}
                        list={subCatList}
                        parent={catSelect}
                        placeholder='une Sous Categorie'
                        reset={myReset}
                    />

                    {!dataSpend?.selected?.subCateg && (<div className="error">une sous categorie est requise</div>)

                    }
                </div>


            }
        
        </div>
        </>
    )
}