import { useState } from 'react';
import { Form, Input, Switch, Button, Select } from 'antd';
import { usePost } from '../../hooks/usePost';
import { CheckOutlined, CloseOutlined, SendOutlined } from '@ant-design/icons';
import { capitalizeFirstLetter, manageErrorPigApi } from '../../utils';
import { useEffect } from 'react';
import { useFacturationContext } from '../../hooks/useFacturationContext';

const DefaultCustomer = {
    id: null,
    title: "",
    firstName: "",
    lastName: "",
    companyName: "",
    address: "",
    postalCode: "",
    city: "",
    concerne: "",
    visible: true,
}

const FormCustomer = ({ customerInfo, onFinish = () => { } }) => {
    const { dispatch } = useFacturationContext()
    const customer = (customerInfo) ? customerInfo : DefaultCustomer
    const [title, setTitle] = useState(customer.title)
    const [firstName, setFirstName] = useState(customer.firstName)
    const [lastName, setLastName] = useState(customer.lastName)
    const [address, setAddress] = useState(customer.address)
    const [postalCode, setPostalCode] = useState(customer.postalCode)
    const [city, setCity] = useState(customer.city)
    const [checked, setChecked] = useState(customer.visible)
    const [concerne, setConcerne] = useState(customer.concerne)
    const [companyName, setCompanyName] = useState(customer.companyName)
    const { Post, response, error } = usePost()

    const handleInputChange = (v, t) => {
        const val = v.target.value
        switch (t) {
            case 'title':
                setTitle(val)
                break
            case 'firstName':
                setFirstName(val)
                break
            case 'lastName':
                setLastName(val)
                break
            case 'companyName':
                setCompanyName(val)
                break
            case 'concerne':
                setConcerne(val)
                break
            case 'address':
                setAddress(val)
                break
            case 'postalCode':
                setPostalCode(val)
                break
            case 'city':
                setCity(val)
                break
        }
    }
    const handleClick = (v) => {
        setChecked(!checked)
    }

    const handleFinish = async () => {
        const V = JSON.parse(JSON.stringify(customer))
        // console.clear()

        V.title = capitalizeFirstLetter(title)
        V.firstName = capitalizeFirstLetter(firstName)
        V.lastName = capitalizeFirstLetter(lastName)
        V.visible = checked
        V.concerne = capitalizeFirstLetter(concerne)
        V.companyName = companyName
        V.address = address
        V.postalCode = postalCode
        V.city = capitalizeFirstLetter(city)
        // console.log(JSON.stringify(V, 0, 2))

        const path = `customer`
        await Post(path, V)
    }

    useEffect(() => {
        // console.log(response)
        if (response && response.result) {
            if (process.env.NODE_ENV !== 'production') {
                // console.log(JSON.stringify(response.logs, 0, 2))
            }
            onFinish(true)
            dispatch({type:'SET_DATA', payload:{
                requestType: 'customers',
                result: response.result
            }})
        }
    }, [response])

    return (<>
        <Form
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            onFinish={handleFinish}
        >
            {error && <ErrorScreen error={error} />}
            <FormName customerInfo={customer} onChange={(v, t) => handleInputChange(v, t)} />
            <Form.Item
                label="Concerne"
            >
                <Input value={concerne}
                               placeholder="Personne"
                               onChange={(v) => handleInputChange(v, 'concerne')}
                />
            </Form.Item>
            <Form.Item
                label="Companie">
                <Input value={companyName}
                                placeholder="Complement d addresse"
                                onChange={(v) => handleInputChange(v, 'companyName')}
                />
            </Form.Item>
            <Form.Item
                label="Adresse">
                <Input value={address}
                                 placeholder="Rue, Numero"
                                 onChange={(v) => handleInputChange(v, 'address')}
                />
            </Form.Item>
            <Form.Item
                label="Code Postal">
                <Input value={postalCode}
                              placeholder="Code Postal"
                              onChange={(v) => handleInputChange(v, 'postalCode')}
                />
            </Form.Item>
            <Form.Item
                label="Ville">
                <Input value={city}
                    placeholder="Ville"
                    onChange={(v) => handleInputChange(v, 'city')}
                />
            </Form.Item>

            <Form.Item
                label="Visible"
                style={{ marginBottom: 0 }}
                wrapperCol={{ offset: 4 }}

            >
                <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={checked}
                    onChange={(e) => handleClick(e)}
                // size="small"
                />
            </Form.Item>


            <Form.Item
                wrapperCol={{ offset: 17 }}

            >
                <Button htmlType='submit'
                // disabled={(!valToSend.customerId)}
                >{(customer.id) ? 'Modifier' : 'Créer'} <SendOutlined /></Button>
            </Form.Item>
        </Form>
    </>)
}


const FormName = ({ customerInfo, onChange }) => {
    const customer = (customerInfo) ? customerInfo : DefaultCustomer
    const [title, setTitle] = useState(customer.title)
    const [firstName, setFirstName] = useState(customer.firstName)
    const [lastName, setLastName] = useState(customer.lastName)

    const handleInputChange = (v, t) => {
        const val = v.target.value
        switch (t) {
            case 'title':
                setTitle(val)
                break
            case 'firstName':
                setFirstName(val)
                break
            case 'lastName':
                setLastName(val)
                break
        }
        onChange(v, t)
    }
    return (
        <Form.Item
            label="Nom"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
        >
            <Input.Group compact>
                <Form.Item
                    noStyle

                >
                    <Input
                        style={{
                            width: '25%',
                        }}
                        value={title}
                        onChange={(v) => handleInputChange(v, 'title')}
                        placeholder="Titre"
                    />
                </Form.Item>
                <Form.Item
                    noStyle
                >
                    <Input
                        style={{
                            width: '35%',
                        }}
                        placeholder="Prénom"
                        value={firstName}
                        onChange={(v) => handleInputChange(v, 'firstName')}
                    />
                </Form.Item>
                <Form.Item
                    noStyle
                >
                    <Input
                        style={{
                            width: '40%',
                        }}
                        placeholder="Nom"
                        value={lastName}
                        onChange={(v) => handleInputChange(v, 'lastName')}
                    />
                </Form.Item>
            </Input.Group>
        </Form.Item>
    )
}

export const ErrorScreen = (error) => {
    let err = manageErrorPigApi(error.error)
    // console.log(err)
    return (<div className='creaError'>{err}</div>)

}
export default FormCustomer