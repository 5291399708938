import { SelectMenuLieu } from "./SelectMenuLieu"
// import { useSpendContext } from '../hooks/useSpendContext';
import { useState } from "react";



export const SelectLieu = ({ reset }) => {
    // const { dataSpend } = useSpendContext()
    // const { lieux } = dataSpend
    const [error, setError] = useState(null)

    const handleSelect = (value) => {
        // console.log('Value for Lieu: ', value)
    }
    const handleError = (value) => {
        // console.log(value)
        setError(value)
        console.group('Error');
        console.log(value);
        console.error(`action: Create Lieu`);

        console.groupEnd();
    }

    return (
        <div className="lieu">
            <h4>Dans quel lieu</h4>
            <SelectMenuLieu reset={reset}
                placeholder='un Lieu'
                onSelect={handleSelect}
                onCreateError={handleError}
            />
            {(error) &&
                <div className="error">{error}</div>
            }
            {/* <pre><code>Context Value:
                {JSON.stringify(reset, null, "\t")}</code></pre> */}
        </div>
    )
}