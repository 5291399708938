import React, { useEffect, useMemo, useRef, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { usePut } from '../../../hooks/usePut';
import { CheckOutlined, CheckCircleOutlined, ClockCircleOutlined, PrinterOutlined, FileTextOutlined } from '@ant-design/icons';
import { Button, Modal, DatePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { mmtFromYMD, Moment, sleep } from "./../../../utils"
import { useFacturationContext } from '../../../hooks/useFacturationContext';
import FacturesPrint from '../FacturesPrint';
import { useReactToPrint } from 'react-to-print';
import { ExportToCsv } from 'export-to-csv';






const style = {
    borderColor: 'hsl(0, 0%, 80%)',
    width: '100%',
    padding: '0.445rem 0.5rem',
    borderRadius: 4

}

const getFileName = (table) => {
    if (!table) {
        return
    }
    let date = table[0].original.invoiceDate
    date = mmtFromYMD(date).format('DD-MM-YYYY')

    if (table.length < 2) {
        let name = table[0].original.customer.lastName;
        name = `${date}-Facture-${name}`
        return name
    }
    let name = `${date}_${table.length}-Factures`
    return name

}

const modalPrintStyle = {
    top: 10,
}
const disabledDate = (current) => {
    return current > dayjs().endOf('day') || current < dayjs().year(2018).month(0).startOf('month');
};


export default function InvoicesTablePrint({ datas }) {
    const printRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => printRef.current
    })
    const { dispatch } = useFacturationContext()
    const { Put, response, error } = usePut()
    const [nable, setNable] = useState(false)
    const [dispatchType, setDispatchType] = useState(false)
    const [paidDate, setPaidDate] = useState(false)
    const [delayedRow, setDelayedRow] = useState(false)
    //  modal
    const [open, setOpen] = useState(false)
    const [btOkDesabled, setBtOkDesabled] = useState(true)

    // printing
    const [printData, SetPrintData] = useState([])
    const [showPrint, setShowPrint] = useState(false)

    // virtualisation
    const rowVirtualizerInstanceRef = useRef(null)
    const [sorting, setSorting] = useState([])
    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(true);


    const handleOk = () => {
        setOpen(false)
        const row = JSON.parse(JSON.stringify(delayedRow))
        handleClickPaid(row)


    }
    const handleCancel = () => {
        setOpen(false)
    }
    const handleClickPaid = async (row) => {
        let path = `invoicePaid/`
        let data = {
            setPaidDate: paidDate,
            expSerRes: 'invoices'
        }
        setNable(true)
        setDispatchType('SET_INVOICE_PAID')
        await sleep(1)
        if (row.length === 1) {
            // console.log(row)
            // single request do it directly
            path += `${row[0].original.id}`
        } else {
            // multiple rows request
            path += `0`
            data.itemsId = row.map(el => el.original.id)

        }

        // console.log(path)
        // console.log(JSON.stringify(data, 0, 1))
        // console.log(row)
        
        await Put(path, data)
        // console.error('PROCESS DESABLED !')

        setNable(false)
        setPaidDate(false)
        setDelayedRow(null)
        return


    }
    const handleClickDelayedPaid = (row) => {
        // console.log('Delayed ', row)
        setDelayedRow(row)
        setBtOkDesabled(true)
        setOpen(true)
    }
    const handleChangeDate = (value) => {
        if (!value) {
            setBtOkDesabled(true)
            return
        }
        setPaidDate(value.format('YYYY/MM/DD'))
        setBtOkDesabled(false)
        // console.log('Date Change on modal: ', value.format('YYYY/MM/DD'))
    }
    const handleGeneratePrint = (table) => {
        // console.log('generate PDFs Request', table)
        const fileName = getFileName(table)
        document.title = fileName
        SetPrintData(table)
        setShowPrint(true)


    }
    const handleCancelShowPrint = () => {
        document.title = 'Piggy - Facturation'
        setShowPrint(false)
    }

    useEffect(() => {
        if (response) {
            console.log('Paid invoice Response:',response)
            dispatch({ type: dispatchType, payload: response })
            setDispatchType(null);
        }
    }, [response])
    useEffect(() => {
        if (error) {
            console.log('Error Response', error);
        }
    }, [error])

    useEffect(() => {
        //scroll to the top of the table when the sorting changes
        rowVirtualizerInstanceRef.current?.scrollToIndex(0);
    }, [sorting]);
    useEffect(() => {
        // console.log('useEffect ')
        if (typeof window !== 'undefined') {
            // console.log('window is defined ', datas)
            setData(datas);
            setIsLoading(false);
        }
    }, [datas]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'invoiceId',
                header: 'Numero',
                // size: 50,
            },
            {
                accessorKey: 'invoiceDate',
                header: 'Date',
                Cell: ({ cell }) => Moment(cell.getValue()).format('L')
            },
            {
                accessorKey: 'patient',
                header: 'Patient',
            },
            {
                accessorKey: 'products',
                header: 'Seances',
                // size: 50,
                Cell: ({ cell }) => (cell.getValue()) ? cell.getValue().length : 0,
            },
            {
                accessorKey: 'status',
                header: 'Payé',
                // size: 50,
                Cell: ({ cell, row }) => (cell.getValue()) ? <CheckOutlined style={{ color: '#0ac99e' }} /> : <>
                    <Button disabled={nable} shape="circle" onClick={() => handleClickPaid([row])}><CheckCircleOutlined /></Button>
                    <Button disabled={nable} shape="circle" onClick={() => handleClickDelayedPaid([row])}><ClockCircleOutlined /></Button>
                </>,
            },
            {
                header: 'Actions',
                enableGrouping: false,
                // size: 50,
                Cell: ({ cell, row }) => (cell.getValue()) ? <CheckOutlined style={{ color: '#0ac99e' }} /> : <>
                    <Button disabled={nable} shape="circle" onClick={() => handleGeneratePrint([row])}><PrinterOutlined /></Button>
                </>,
            },
        ],
        [nable],
    );
    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        filename: `Export ${Moment().format('LLL')}-Invoices`,
        headers: ['id', 'numero', 'date', 'patient', 'periode']
        // headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleGenerateCsv = (table) => {
        csvExporter.generateCsv(table.map((row) => {
            const data = row.original
            const csv = {
                id: data.id,
                invoiceId: data.invoiceId,
                date: mmtFromYMD(data.invoiceDate).format('LL'),
                patient: data.patient,
                periode: data.periode
            }
            return csv
        }))
    }
    return (
        <div>
            {data &&
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    initialState={
                        {
                            density: 'compact',
                            // pagination: { pageSize: 100 },
                            expanded: false,
                            grouping: ['invoiceDate']

                        }

                    }
                    // enableRowActions
                    enablePagination={false}
                    enableRowSelection
                    enableExpanding
                    enableGrouping
                    enableStickyHeader
                    // enableRowNumbers
                    sortDescFirst={false}
                    // virtualisation

                    state={{ isLoading, sorting }}
                    enableBottomToolbar={false}
                    enableColumnVirtualization
                    enableGlobalFilterModes
                    onSortingChange={setSorting}
                    enableRowVirtualization
                    rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
                    rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
                    columnVirtualizerProps={{ overscan: 2 }} //optionally customize the column virtualizer

                    muiToolbarAlertBannerProps={{
                        sx: {
                            backgroundColor: '#6200ee24'
                        }
                    }}
                    muiSelectAllCheckboxProps={{
                        sx: { color: '#6200ee' }

                    }}
                    muiSelectCheckboxProps={{
                        sx: { color: '#6200ee' }
                    }}
                    muiTableContainerProps={{ sx: { maxHeight: '500px' } }}
                    renderDetailPanel={({ row }) => (<div className='tableExpended'>
                        {row.original.products.map(seance => <span key={`sp${seance.id}`} >{seance.name}</span>)}
                    </div>)}

                    renderTopToolbarCustomActions={({ table }) => {
                        const startPrinting = () => {
                            handleGeneratePrint(table.getSelectedRowModel().flatRows)
                        };

                        const startCsvDownload = () => {
                            handleGenerateCsv(table.getSelectedRowModel().flatRows)
                        }
                        const startPaidGroup = () => {
                            handleClickPaid(table.getSelectedRowModel().flatRows)
                        }
                        const startPaidOutDatedGroup = () => {
                            handleClickDelayedPaid(table.getSelectedRowModel().flatRows)
                        }
                        const isPayable = () => {
                            console.log('isPayable')
                            if (!table.getIsSomeRowsSelected()) {
                                return true;

                            } else {
                                return false;

                            }
                        }
                        return (
                            <div style={{ display: 'flex', gap: '0.5rem' }}>
                                <Tooltip title="Imprimer les factures selectionnées" color={'#6200ee'}>
                                    <Button
                                        shape="circle"
                                        onClick={() => startPrinting()}
                                        disabled={!table.getIsSomeRowsSelected()}
                                    ><PrinterOutlined /></Button>
                                </Tooltip>

                                <Tooltip title="Exporter en CSV les factures selectionnées" color={'#6200ee'}>
                                    <Button
                                        shape="circle"
                                        disabled={!table.getIsSomeRowsSelected()}
                                        onClick={() => startCsvDownload()}
                                        icon={<FileTextOutlined />}
                                    />
                                </Tooltip>

                                <Tooltip title="Payements Recus pour les factures selectionnées" color={'#6200ee'}>
                                    <Button
                                        shape="circle"
                                        disabled={!table.getIsSomeRowsSelected()}
                                        onClick={() => startPaidGroup()}
                                        icon={<CheckCircleOutlined />}
                                    />
                                </Tooltip>
                                <Tooltip title="Payements Recus avec une date precise pour les factures selectionnées" color={'#6200ee'}>
                                    <Button
                                        shape="circle"
                                        disabled={!table.getIsSomeRowsSelected()}
                                        onClick={() => startPaidOutDatedGroup()}
                                        icon={<ClockCircleOutlined />}
                                    />
                                </Tooltip>
                            </div>
                        );
                    }}
                />
            }


            <Modal
                title="Valider un payement"
                centered
                open={open}
                onOk={handleOk}
                okButtonProps={{
                    disabled: btOkDesabled,
                }}
                // confirmLoading={confirmLoading}
                onCancel={handleCancel}
                destroyOnClose
            >
                <p>Choisi la date du payement</p>

                <DatePicker
                    style={style}
                    format={'DD/MM/YYYY'}
                    defaultPickerValue={undefined}
                    onChange={(value) => handleChangeDate(value)}
                    disabledDate={disabledDate}

                />


            </Modal>
            <Modal
                open={showPrint}
                onCancel={handleCancelShowPrint}
                onOk={handlePrint}
                okText={'Imprimer'}
                destroyOnClose
                width={1000}
                style={modalPrintStyle}
                title={`Impréssion de ${printData.length} Facture${(printData.length > 1) ? 's' : ''}`}
            >
                <div className="contFactModal">
                    <div ref={printRef}>
                        {printData.map((el, i) => <>
                            <div className="page-break" />
                            <FacturesPrint key={i} invoice={el.original} />
                        </>)}
                    </div>
                </div>

            </Modal>
        </div>
    )
}