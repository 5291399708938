import { useFacturationContext } from '../../../hooks/useFacturationContext';
import { useMemo } from 'react';
import { EditOutlined, DeleteTwoTone, WarningTwoTone } from '@ant-design/icons';
import { Button, Popconfirm } from 'antd';
import { Moment, toCurrency } from '../../../utils';
import { useDelete } from '../../../hooks/useDelete';
import MaterialReactTable from 'material-react-table';
import DrawerAddSeance from './DrawerAddSeance';
import { useEffect, useRef, useState } from 'react';
const Seances = () => {
    const { dataFactu, dispatch } = useFacturationContext()
    const { Delete, respDelete,isLoading } = useDelete()
    const { session } = dataFactu
    const rowVirtualizerInstanceRef = useRef(null)
    const [sorting, setSorting] = useState([])
    const handleEdit = (r) => {

        dispatch({ type: 'SET_EDIT_SEANCE', payload: r[0].original })
    }


    const handleDelete = async (row) => {
        const seanceId = row[0].original.id;
        console.log('DELETE REQUEST FOR SEANCE ID: ', seanceId)
        await Delete(`product/${seanceId}`)
    }
    useEffect(() => {
        if (respDelete && respDelete.result) {
            // console.log(respDelete)
            if (respDelete.products) {
                // console.log('invoice future detecetect')
                const data = {
                    requestType: 'session',
                    result: respDelete.products
                }
                dispatch({ type: 'SET_DATA', payload: data })

            }
        }
    }, [respDelete])

    const columns = useMemo(
        () => [

            // {
            //     accessorKey: 'id',
            //     header: 'Numero',
            //     size: 50,
            // },
            {
                accessorKey: 'date',
                header: 'Date',
                Cell: ({ cell }) => Moment(cell.getValue()).format('L')
            },
            {
                accessorKey: 'patient',
                header: 'Patient',
                // Cell: ({ cell , row}) => JSON.stringify(row.original.customer,0,1)

            },
            {
                accessorKey: 'price',
                header: 'montant',
                Cell: ({ cell }) => toCurrency(cell.getValue())

            },
            {
                accessorKey: 'invoiceId',
                header: 'facture',
            },

            {
                header: 'Actions',
                // size: 50,
                Cell: ({ cell, row }) => (cell.getValue()) ? <>NotEdit</> : <>
                    <Button disabled={(row.original.invoiceId)} shape="circle" onClick={() => handleEdit([row])}><EditOutlined /></Button>
                    {!row.original.invoiceId &&
                        <Popconfirm
                            title="Supprimer la séance"
                            description="es tu sûr ?"
                            onConfirm={() => handleDelete([row])}
                            icon={<WarningTwoTone twoToneColor="#ff1493" />}
                            // onCancel={cancel}
                            okText="Oui"
                            cancelText="Non"
                        >
                            <Button danger shape="circle" icon={<DeleteTwoTone twoToneColor={(row.original.invoiceId) ? '#cdcdcd' : '#ff1493'} />} />
                            {/* <a href="#">Delete</a> */}
                        </Popconfirm>
                    }


                </>,
            },
        ],
        [],
    );

    return (<>
        {session &&
            <>
                <h4>Seances <small>({session.length}x)</small><DrawerAddSeance /></h4>
                <MaterialReactTable
                    initialState={
                        {
                            density: 'compact',
                            // pagination: { pageSize: 100 },
                        }
                    }
                    sortDescFirst={false}

                    columns={columns}
                    data={session}
                    enablePagination={false}
                    enableGrouping
                    muiTableContainerProps={{ sx: { maxHeight: '500px' } }}

                    state={{ isLoading, sorting }}
                    enableBottomToolbar={false}
                    enableColumnVirtualization
                    enableGlobalFilterModes
                    onSortingChange={setSorting}
                    enableRowVirtualization
                    rowVirtualizerInstanceRef={rowVirtualizerInstanceRef} //optional
                    rowVirtualizerProps={{ overscan: 5 }} //optionally customize the row virtualizer
                    columnVirtualizerProps={{ overscan: 2 }} //optionally customize the column virtualizer

                />
            </>
        }


    </>)
}

export default Seances