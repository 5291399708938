import * as moment from "moment"
import 'moment/locale/fr';
moment.locale('fr')

export const Moment = moment

export const mmtFromYMD = (string) =>{
    return moment(string, 'YYYY-MM-DD')
}
export const flattenObj = (data, exclude) => {

    const isObject = (data) =>
        Object.prototype.toString.call(data) === "[object Object]";
    const isArray = (data) =>
        Object.prototype.toString.call(data) === "[object Array]";

    const flatten = (data) => {
        if (!data.attributes) return data;

        return {
            id: data.id,
            ...data.attributes,
        };
    };
    if (!isArray(exclude)) {
        exclude = []
    }

    if (isArray(data)) {
        return data.map((item) => flattenObj(item, exclude));
    }

    if (isObject(data)) {
        if (isArray(data.data)) {
            data = [...data.data];
        } else if (isObject(data.data)) {
            data = flatten({ ...data.data });
        } else if (data.data === null) {
            data = null;
        } else {
            data = flatten(data);
        }

        for (const key in data) {
            // exclusion demandé
            if (exclude.includes(key)) {
                delete data[key]
            } else {
                data[key] = flattenObj(data[key], exclude);
            }
        }

        return data;
    }

    return data;
};
export const toCurrency = (value) => {
    if (!value) return '0.00'
    let nbr = parseFloat(value)
    let result = nbr.toLocaleString('fr-FR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        roundingIncrement: 1,
    })
    result = result.replace(',', '.')
    return result
}

export const capitalizeFirstLetter = (string) => {
    if(string === '' || string === null){
        return ''
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export const sleep = ms => new Promise(r => setTimeout(r, ms));

export const manageErrorPigApi = (error) => {

    if (error.response) {
        // response de strapi
        // contient data, qui contient la reponse envoyé
        let message = (error.response?.data?.message)
            ? error.response?.data?.message
            : false
        if (typeof message === 'string') {
            return message
        }
    }
    if (error?.request?.statusText) {
        return error.request.statusText + ' / ' + error.request.status
    }
    return false
}
export const nbrCurrency = (nbr, currency) => {
    if (!currency) {
        currency = 'USD'
    }
    return nbr.toLocaleString('de-DE', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}

export const catSort = (cat) => {
    return cat.sort((a, b) => a.title.localeCompare(b.title))
}
