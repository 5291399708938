import '../../scss/recapYear.scss'

import { useEffect, useState } from "react"
import { useAxios } from "../../hooks/useAxios"
import { useHomeContext } from "../../hooks/useHomeContext"
import moment from "moment/moment"
import qs from 'qs'
import { getCategFromParentItems } from "../../resolvers"
import { capitalizeFirstLetter, toCurrency } from '../../utils'
import { Tooltip, Select } from 'antd';
import { EyeTwoTone } from '@ant-design/icons'
import Loading from '../Loading'

const getYearDays = (date) => {
    let mmt = moment(date)
    let start = mmt.startOf('year').format('YYYY-MM-DD')
    let end = mmt.endOf('year').format('YYYY-MM-DD')
    return { start, end }
}


export default function YearRecap() {
    const { dataSpend, dispatch } = useHomeContext()
    const { sendAxios, response, isLoading } = useAxios()
    const update = (date) => {
        dispatch({ type: 'CLEAR' })
        let result = getYearDays(date)
        // dispatch({type:'CLEAR'})
        dispatch({ type: 'SET_DATEYEAR', payload: date })

        const query = qs.stringify({
            filters: {
                date: {
                    gte: result.start,
                    lte: result.end,
                }
            }
        }, {
            encodeValuesOnly: true, // prettify URL
        });
        sendAxios(`expenses?${query}`)
    }

    useEffect(() => {
        update(moment().toISOString())
        return
    }, [])
    useEffect(() => {
        // console.log('response in YearRecap:', response)
        if (response && response.result) {
            dispatch({ type: 'SET_DATA', payload: response })
        } else {
            dispatch({ type: 'SET_DATA', payload: [] })


        }
    }, [response]);
    const handleChange = (value) => {
        update(value)
    }

    return (
        <div className='annualRecap'>
            {isLoading ? <Loading /> : <>
                {dataSpend && dataSpend?.data?.byParent ?
                    <div className='recapContainer'>
                        <div className="styckyDate">
                            <div className="parent">
                                <div className="lineCat">
                                    <div className="catTitle">
                                        <h2>&nbsp;</h2>
                                    </div>
                                    <div className="monthLineCat">
                                        <div className="AnualCumul">
                                            <div><SelectDateByYear onChange={handleChange} /></div>
                                        </div>
                                        <div className="oneMonthCat pair">
                                            <h3>Janvier</h3>
                                        </div>
                                        <div className="oneMonthCat impair">
                                            <h3>Fevrier</h3>
                                        </div>
                                        <div className="oneMonthCat pair">
                                            <h3>Mars</h3>
                                        </div>
                                        <div className="oneMonthCat impair">
                                            <h3>Avril</h3></div>
                                        <div className="oneMonthCat pair">
                                            <h3>Mai</h3>
                                        </div>
                                        <div className="oneMonthCat impair">
                                            <h3>Juin</h3>
                                        </div>
                                        <div className="oneMonthCat pair">
                                            <h3>Juillet</h3>
                                        </div><div className="oneMonthCat impair">
                                            <h3>Aout</h3>
                                        </div>
                                        <div className="oneMonthCat pair">
                                            <h3>Septembre</h3>
                                        </div>
                                        <div className="oneMonthCat impair">
                                            <h3>Octobre</h3>
                                        </div>
                                        <div className="oneMonthCat pair">
                                            <h3>Novembre</h3>
                                        </div>
                                        <div className="oneMonthCat impair">
                                            <h3>Decembre</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {dataSpend?.data.byParent.map(categ => <OneCatParent key={categ.id} parent={categ} />)}
                        {!dataSpend?.data?.byParent.length && <div className='noResult'>Aucun Resultat</div>}

                    </div> :
                    <div>Aucun Resultat !</div>
                }
            </>}

        </div>
    )
}

export const OneCatParent = ({ parent }) => {

    const [categ] = useState(getCategFromParentItems(parent))
    return (
        <div className='parent'><h1>{capitalizeFirstLetter(parent.title)}</h1>
            {categ ? categ.map(categ => <OneCategorie key={categ.id} categorie={categ} />) : 'No sub Categ'}
        </div>
    )
}

export const OneCategorie = ({ categorie }) => {

    // console.log(categorie)
    if (!categorie) {
        return (<></>)
    }
    const depenses = categorie.items
    const keymonth = `monthCateg_${categorie.id}_${categorie.parent_id}`
    // console.log('keymonth', keymonth)
    return (
        <div className='lineCat'><div className='catTitle'><h2>{capitalizeFirstLetter(categorie.title)}</h2></div>
            <Month key={keymonth} depenses={depenses} />
        </div>
    )
}

export const Month = ({ depenses }) => {
    const { dataSpend } = useHomeContext()
    if (!depenses) {
        return
    }
    let year = moment().format('YYYY')
    if (dataSpend.dateYear) {
        year = moment(dataSpend.dateYear).format('YYYY')

    }
    const getMonth = (i) => {
        const formattedI = ("0" + (i + 1)).slice(-2)
        const strdate = `${year}-${formattedI}-10`
        const date = moment(strdate, 'YYYY-MM-DD', 'fr', true)
        // const date = moment().month(i).date(1).hours(0).minutes(1).seconds(1).milliseconds(0);
        const start = moment(date).startOf('month')
        const end = moment(date).endOf('month')
        const startf = start.format('X')
        const endf = end.format('X')

        return {
            start, startf, end, endf, date, formattedI, strdate
        }
    }
    const res = []
    let i = 0
    let cumul = 0.00
    const CumulKey = []
    while (i < 12) {
        const dates = getMonth(i)
        const filter = depenses.filter(el => el.dateU >= dates.startf && el.dateU <= dates.endf)
        const money = {
            EUR: 0.00,
            CHF: 0.00
        }
        let value = 0
        if (filter.length) {
            filter.forEach(dep => {
                value += dep.amounts.chf
                const key = dep.currency
                money[key] += dep.amount
            })
        }
        cumul += value
        const keySrc = depenses[0]
        const key = `month_${keySrc.catParent.id}_${keySrc.categorie.id}_${i}`
        if (CumulKey.includes(key)) {
            console.log('doublon trouver:', key)
        }
        CumulKey.push(key)

        // console.log('month key:', key)
        res.push((<div
            key={key}
            className={`oneMonthCat ${(i % 2 === 0) ? 'pair' : 'impair'}`}
        >
            <div className='oeil'>
                {(money.EUR > 0) ? (
                    <Tooltip
                        color={'rgba(0,0,0,0.645)'}
                        title={`EUR: ${toCurrency(money.EUR)} | CHF: ${toCurrency(money.CHF)}`}>
                        <EyeTwoTone />
                    </Tooltip>
                ) : <span>&nbsp;</span>}
                &nbsp;{toCurrency(value)}
            </div>
        </div>))
        i++

    }
    // console.log('CumulKey',CumulKey)
    const annual = (<div className='AnualCumul'>
        <div>
            {toCurrency(cumul)}
        </div>
    </div>)
    res.unshift(annual)
    return (
        <div className="monthLineCat">{res}</div>
    )
}


export const SelectDateByYear = ({ onChange }) => {
    const { dataSpend } = useHomeContext()
    let year = moment().format('YYYY')
    if (dataSpend.dateYear) {
        year = moment(dataSpend.dateYear).format('YYYY')

    }
    let opts = []
    let mmnt = moment()
    let i = parseInt(mmnt.format('YYYY'))
    // let n = null
    while (i >= 2018) {
        // if (i === 0) {
        //     n = 0
        // } else {
        //     n = 1
        // }
        // const m = mmnt.subtract(n, 'year')
        const m = moment().year(i).month(1).day(1)
        const year = {
            value: m.toISOString(),
            label: m.format('YYYY')
        }
        i--
        opts.push(year)
    }
    const handleChange = (value) => {
        onChange(value)
    }
    const selected = opts.find(el => el.label === year)
    return <Select
        size='small'
        defaultValue={opts[0]}
        style={{
            width: 80,
        }}
        onChange={handleChange}
        value={selected}
        options={opts}
    // dropdownStyle={{backgroundColor:'purple'}}
    />
}