import { Spin } from 'antd';
export const Loading = ({ cover, tip = 'loading' }) => {
    return (
        <div className={`loading ${(cover) ? 'loadingCover' : ''}`}>
            <Spin tip={tip} size="large">
                <div className="content" />
            </Spin>
        </div>
    )
}
export default Loading