import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { axiosInstance as axios } from '../../AxiosToken';
import { usePost } from '../../hooks/usePost';
import { useSpendContext } from '../../hooks/useSpendContext';


const createFromResponse = (values) => {
    // console.log('createFromResponse', values.data)
    let id = values.data.id
    let name = values.data.name
    let city = values.data.city
    let title = `${name} - ${city}`
    return {
        label: title,
        value: id,
        id,
        name,
        city
    }
}
const optioneur = (list) => {
    if (!list) {
        return
    }
    const myList = JSON.parse(JSON.stringify(list))
    if (list) {
        myList.map(el => {
            let str = el.name.charAt(0).toUpperCase() + el.name.slice(1)
            el.label = `${str} - ${el.city}`
            el.value = el.id
            return el
        })
        // console.log('Liste Lieux', myList , list)
    }

    return myList
}
export const SelectMenuLieu = ({ onSelect, placeholder, onCreateError, reset }) => {
    const { Post, error, response } = usePost()
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState();
    const [value, setValue] = useState();
    const { dataSpend, dispatch } = useSpendContext()
    const { lieux } = dataSpend

    // console.log(lieux)
    useEffect(() => {
        // console.log('lieux use effect')
        return setOptions(optioneur(lieux))
    }, [lieux])
    useEffect(() => {
        if (reset === true) {
            setValue(null)
        }
        return
    }, [reset])

    useEffect(() => {
        if (error) {
            setIsLoading(false)
            onCreateError(error.message)
        }
    }, [error])
    useEffect(() => {
        if (response) {
            dispatch({ type: 'SET_LIEUX', payload: response.lieux })
            setIsLoading(false)
            // console.log('Response Found:', response.lieux)
        }
    }, [response])

    const handleCreate = async (inputValue) => {
        setIsLoading(true);
        const values = inputValue.split('-')
        if (!values[1]) {
            const err = <div>Format requis non trouvé<br /> Lieu<b> - </b>Ville </div>
            onCreateError(err)
            setIsLoading(false);
            return
        }
        let path = `lieux`
        let data = {
            name: values[0].trim(),
            city: values[1].trim()
        }
        await Post(path, data)
        // axios.post(url, data)
        //     .then(response => {
        //         // return  response;
        //         console.log(response)
        //         const newOption = createFromResponse(response.data);
        //         setIsLoading(false);
        //         console.log('response & new Option', response, newOption)

        //         setOptions((prev) => [...prev, newOption]);
        //         handleSetValue(newOption);
        //         dispatch({
        //             type: 'SET_NOTIF', payload: {
        //                 message: 'Lieu Ajouté',
        //                 type: 'success'
        //             }
        //         })
        //     })
        //     .catch((error) => {
        //         //return  error;
        //         // onCreateError(`Erreur serveur: ${error.message}`)
        //         dispatch({
        //             type: 'SET_NOTIF', payload: {
        //                 message: error.message,
        //                 type: 'error'
        //             }
        //         })
        //         console.log(error)
        //     })
        //     .then(() => {
        //         setIsLoading(false);

        //     })
    };
    const handleSetValue = (newValue) => {
        setValue(newValue)
        onSelect(newValue)
        dispatch({ type: 'SET_SELECT_LIEU', payload: newValue })
    }

    return (
        <>
            <CreatableSelect
                isClearable
                isDisabled={isLoading}
                isLoading={isLoading}
                onChange={(newValue) => handleSetValue(newValue)}
                onCreateOption={handleCreate}
                options={options}
                value={value}
                placeholder={`Choisis/Creer une ${placeholder}`}
            />
            {/* <pre>
                <code>
                    {JSON.stringify(lieux,0,1)}
                </code>
            </pre> */}
        </>
    );
};