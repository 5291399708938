import { useState } from 'react'
import { useSpendContext } from './useSpendContext';
import { ApiUrl } from '../services'
import { axiosInstance as axios } from '../AxiosToken';
import { manageErrorPigApi } from '../utils';

export const usePutCategorie = () => {
  const { dispatch } = useSpendContext()
  const [error, setError] = useState(null)
  // const [response, setResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  const editCategorie = async (id, data) => {
    setIsLoading(true)
    setError(null)
    let url = ApiUrl()
    let path = 'categories'

    try {
      if (id) {
        path += `/${id}`
      }
      let lnk = `${url}/api/${path}`
      console.log(lnk, data);

      const respAxios = await axios.put(lnk, data)
      let result = respAxios.data
      console.log('Update categories result: ', result)
      // setResponse(result)
      // data.id = id
      // data.parent_id = result.val.parent_id
      // data= result
      setIsLoading(false)
      dispatch({type:'UPDATE_ONE_CATEGORIE', payload: result})
      dispatch({
        type: 'SET_NOTIF', payload: {
          message: 'Categorie Modifiée !',
          type: 'success'
        }
      })
    } catch (error) {
      let errorMessage = manageErrorPigApi(error)
      setError(error)
      console.log(error)
      dispatch({
        type: 'SET_NOTIF', payload: {
          message: (errorMessage) ? errorMessage : error.message,
          type: 'error'
        }
      })
      setIsLoading(false)
    }
  }
  return { editCategorie, isLoading, error }
}


