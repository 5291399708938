import { DatePicker, Input } from 'antd';
import { useSpendContext } from '../../hooks/useSpendContext';
import dayjs from 'dayjs';
import { motion, AnimatePresence } from "framer-motion"
import { useEffect, useState } from 'react';

const style = {
    borderColor: 'hsl(0, 0%, 80%)',
    width: '100%',
    padding: '0.445rem 0.5rem',
    borderRadius: 4

}
const variant = {
    initial: { opacity: 0, x: 100 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 100, transition: { duration: 0.11 } }
}
export const SelectDate = ({ reset }) => {
    const [value, setValue] = useState('')
    const [reccurence, setReccurence] = useState(null)
    const [dateReccu, setDateReccu] = useState(null)
    const { dispatch, dataSpend } = useSpendContext()
    const { selected } = dataSpend
    const { depenseType } = selected
    const disabledDateReccurent = (current) => {
        return current < dayjs().year(2018).month(0).startOf('month');
    };
    const disabledDate = (current) => {
        return current > dayjs().endOf('day') || current < dayjs().year(2018).month(0).startOf('month');
    };
    const handleChange = (value, string) => {
        setValue(value)
        // console.log(value.toISOString(), string)
        dispatch({ type: 'SET_SELECT_DATE', payload: value.toISOString() })
    }
    const setReccurencyPayment = () => {
        // console.log('setReccurencyPayment')
        const val = {
            date: dateReccu,
            reccurence: reccurence
        }
        // console.log(val)
        dispatch({ type: 'SET_RECCU_PAYMNT', payload: val })
    }

    const handleChange2 = (value) => {
        // la valeur envoyer par onBlur differe de on change !
        if (value?.target) {
            let [dd, mm, yyyy] = value.target.value.split('/')
            value = `${yyyy}-${mm}-${dd}`
            // console.log(value)

        } else {
            value = value.toISOString().split('T')[0]
            // console.log(value)
        }

        let strapiDate = value
        setDateReccu((strapiDate))
        setReccurencyPayment()

    }
    const handleInputChange = (el) => {
        // console.log('Change input:', el.target.value)
        setReccurence(el.target.value)
        setReccurencyPayment()
    }
    useEffect(() => {
        if (reset === true) {
            // console.log('reset For Date', reset)
            setValue('')
            setDateReccu('')
            setReccurence(null)
        }
    }, [reset])
    return (
        <div className="dateSelect">
            <AnimatePresence>
                {!depenseType &&
                    <motion.div
                        variants={variant}
                        initial='initial'
                        animate='animate'
                        exit='exit'
                        transition={{ duration: 0.33, ease: "easeOut", delay: 0.33 }}
                    >
                        <h4>Date</h4>
                        <DatePicker
                            style={style}
                            value={value}
                            disabledDate={disabledDate}
                            format="DD/MM/YYYY"
                            onChange={handleChange}
                        // onBlur={(e)=>handleChange(e.target.value)}
                        />
                    </motion.div>
                }
            </AnimatePresence>
            <AnimatePresence>
                {depenseType &&
                    <motion.div

                        variants={variant}
                        initial='initial'
                        animate='animate'
                        exit='exit'
                        transition={{ duration: 0.33, ease: "easeOut" }}
                    >
                        <h4>Date <small>Premier prelevement</small></h4>
                        <DatePicker
                            style={style}
                            // disabledDate={disabledDate}
                            format="DD/MM/YYYY"
                            onChange={handleChange2}
                            onBlur={handleChange2}
                            disabledDate={disabledDateReccurent}
                        />
                        <h4>Nombre de prelevement:</h4>
                        <div className="reccuInput">
                            <Input
                                type="number"
                                min="0"
                                step="1"
                                onChange={handleInputChange}
                                onBlur={handleInputChange}
                            />
                            <i>laisser vide, ou mettre 0 pour un payement sans fin</i>
                        </div>

                    </motion.div>
                }
            </AnimatePresence>


        </div>
    )
}