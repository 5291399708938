
import { delay, motion } from "framer-motion";

const bg = {
    hidden: {
        opacity: 0,
        pathLength: 0,
        fill: "rgba(255, 255, 255, 0)"
    },
    visible: {
        opacity: 1,
        pathLength: 1,
        fill: "rgba(255, 170, 170, 1)"
    }
};
const smile = {
    hidden: {
        opacity: 0,
        pathLength: 0,
        fill: "rgba(255, 255, 255, 0)"
    },
    visible: {
        opacity: [0.5, 5, 0.3],
        pathLength: [0, 1, 0.3],
        fill: "rgba(127, 63, 63, 0.991)"
    }
};

const baudi = {
    hidden: {
        opacity: 0,
        pathLength: 0,
        fill: "#ff8080"
    },
    visible: {
        opacity: [1, 0.6, 0.4, 1],
        pathLength: [1, 0, 1],
        fill: "#f66"
    }
};

const style = {
    width: '56%',
    overflow: 'visible',
    stroke: '#000',
    strokeWidth: 50,
    strokeLinejoin: 'round',
    strokeLinecap: 'round'
}

const strk = {
    path4144: {
        stroke: 'rgba(255, 100, 100, 0.91)'
    },
    path10: {
        stroke: '#f66'
    },
    path8: {
        stroke: 'rgba(255, 170, 170, 1)'
        // stroke: '#ff8080'
    },
    path6: {
        stroke: 'rgba(127, 63, 63, 0.991)'
    },
}

const container = {
    width: '200px',
    height: '200px',
    display: 'flex',
    placeContent: 'center',
    overflow: 'hidden',
    // background: 'rgba(0,0,0, 0.2)',
    // borderRadius: ' 30px'
}
const vContainer = {
    hidden: { opacity: 0 },
    show: {
        opacity: 1,
        transition: {
            delayChildren: 2.4,
        }
    }
}

export const AnimPiggy = () => {

    return (
        <div style={container}>
            <motion.svg style={style} id="svg2" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 2987 2352"
                variants={vContainer}
                initial="hidden"
                animate="show"
            >
                {/* <g id="g4153"> */}

                <motion.path
                    id="path4144"
                    style={strk.path4144}
                    d="m733.07 14.328c-48.79-0.171-97.59 6.93-144.36 21.871 72.18 104.84 104.69 237.66 83.49 363.42-157.35 118.64-292.44 268.87-383.86 444.16-51.13-5.64-106.34-6.63-153.38 17.44-63.847 29.79-108.51 95.2-114.92 165.18-0.115 210.3 0.047 420.7-0.091 631 1.613 106.5 99.901 199.4 206.25 195.1 25.95 2.6 52.45-3 77.6-4.2 45.86 80.3 99.07 157.1 161.34 225.4 162.55 183.1 374.46 318.4 601.56 407.2-5.7 64.4-2.9 132.3-1.7 197.9 10.3 124.1 116 231.3 239.8 243.8 132.5 17.3 265.8-79 293.9-208.9 9.7-36.7 7.2-74.9 7.7-112.4 123.2 8.8 246.9 4.5 369.8-7.5 1 34.3-2.4 68.9 3.3 102.9 17.9 115 116.8 210.9 232.3 225.2 102 14.4 208.3-36.4 263.1-123.2 29.9-45.7 44.4-100.6 43.9-154.9-0.9-64.4 4.1-129.9-9.8-193.2 246.2-109.5 471.6-277.4 625.8-500.8 151.6-215.3 225.4-486.2 191.4-748.4 80 5.5 164.1-7.7 232.5-51.4 53.7-33.2 94.3-84.8 118.9-142.4 28.1-68.43 43.5-144.24 31.4-217.91-8.7-55.41-35.5-108.64-77.8-145.88-73.2-66.15-188.8-88.61-277.7-41.52-59.4 30.38-89.2 104.05-72.3 167.66 7.3 8.82 15.5 20.85 25.8 24.83 16.5-24.83 28.4-53.95 52.7-72.6 24.7-21.02 57.4-39.44 91-31.89 26.4 7.05 44.9 29.91 54.5 54.46 20.4 53.83 17 118.45-14 167.69-23.4 39.38-71.9 59.32-116.5 52.85-45.7-4.27-88.1-24.6-126.6-48.38-59.1-135.61-144.8-262.71-248.2-366.37-171.5-178.64-391.3-308.29-624.8-389.42-405.3-139.62-857.4-137.41-1261.8 4.62-6 0.76-12.3 7.13-18.5 4.73-92-99.613-226.84-155.67-361.73-156.14z"
                    // fill="#faa"
                    transform="scale(.8)"
                    variants={bg}
                    initial="hidden"
                    animate="visible"
                    transition={{
                        default: { duration: 1, ease: "easeInOut", },
                        fill: { duration: 0.5, delay: 1.6 },
                        pathLength: { duration: 2, delay: 0, ease: [0, 1, 3, 1] }
                    }}
                />
                <motion.path
                    id="path6"
                    d="m1376.5 209.46c172.1-3.51 345.5 26.87 505.1 92.01 3.7 35.39 0.3 72.56 1.2 108.35-4.3 0.04-8-3.2-12-4.67-261.2-119.77-567.7-131.42-839.6-41.11-2.8-30.68-0.4-62.04-1.2-92.97 1.3-3.68-1.4-10.3 5.1-10.02 110.7-32.85 226.1-49.3 341.4-51.59z"
                    style={strk.path6}
                    // fill="#7f3f3f" 
                    variants={smile}
                    initial="hidden"
                    animate="visible"
                    transition={{
                        default: { duration: 5, ease: "easeInOut", repeat: Infinity, repeatDelay: 1 },
                        fill: { duration: 5, ease: [0.5, 1, 3, 1.5] },
                        // repeat: Infinity,
                        // repeatDelay: 1
                    }}
                />
                <motion.path
                    style={strk.path8}
                    variants={baudi}
                    initial="hidden"
                    animate="visible"
                    transition={{

                        default: { duration: 4, ease: "easeInOut", repeat: Infinity, repeatDelay: 1 },
                        fill: { duration: 4, ease: [0.5, 1, 3, 1.5] },
                    }}

                    id="path8"
                    // fill="#ff8080"
                    d="m2399.9 440.43c82.7 82.93 151.3 184.61 198.6 293.1 30.8 19.02 64.7 35.29 101.3 38.7 35.7 5.18 74.5-10.77 93.2-42.28 24.7-39.39 27.5-91.09 11.2-134.15-7.7-19.64-22.5-37.93-43.6-43.57-26.9-6.04-53.1 8.7-72.8 25.51-19.4 14.92-29 38.22-42.2 58.08-8.3-3.18-14.8-12.8-20.6-19.86-13.6-50.89 10.3-109.82 57.8-134.13 71.1-37.67 163.6-19.7 222.2 33.22 33.8 29.79 55.2 72.37 62.2 116.7 9.7 58.94-2.6 119.59-25.2 174.31-19.6 46.12-52.1 87.4-95 113.91-54.8 34.99-122 45.53-186 41.19 27.1 209.74-31.9 426.44-153.2 598.64-123.3 178.8-303.6 313.1-500.6 400.7 11.2 50.6 7.2 103 7.8 154.5 0.5 43.5-11.2 87.4-35.1 123.9-43.8 69.5-128.9 110.1-210.5 98.7-92.3-11.5-171.5-88.3-185.8-180.3-4.5-27.2-1.8-54.8-2.7-82.3-98.3 9.6-197.3 13.1-295.8 6-0.3 30 1.6 60.6-6.1 90-22.5 103.8-129.2 180.9-235.1 167.1-99.12-10.1-183.65-95.8-191.9-195.1-0.96-52.4-3.24-106.8 1.37-158.3-181.69-71-351.22-179.3-481.26-325.8 214.8 96.7 455.79 128.6 689.29 104.5 227.6-22.6 449.7-93.8 649.6-204.4 127.6-71.2 247-158.2 350-262 115.2-115.2 211.5-251.2 272-403.02 57.9-143.03 81.8-299.83 66.9-453.55z"
                />
                <motion.path
                    style={strk.path10}
                    id="path10"
                    d="m534.64 608.38c18.29-2.93 28.77 17.11 34.81 31.17 18.88 50.82 20.07 107.07 11.19 160.09-5.38 24.11-11.4 51.11-31.68 67.36-13.74 8.96-29.13-3.47-35.25-15.71-19.67-34.55-21.64-75.59-23.81-114.31 1.43-34.28 4.59-69.52 18.91-101.18 5.63-11.16 12.7-24.17 25.83-27.42z"

                    fill="#f66"
                />
                {/* </g> */}
            </motion.svg>
        </div>

    )
}