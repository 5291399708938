import moment from "moment/moment"
import { useEffect, useState } from "react"
import { useAxios } from "../../hooks/useAxios"
import qs from 'qs'
import { Select, Switch } from "antd"
import { toCurrency } from "../../utils"
import { motion, AnimatePresence } from "framer-motion"

const variant = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 }
}

const getMonthDays = (date) => {
    let mmt = moment(date)
    let start = mmt.startOf('month').format('YYYY-MM-DD')
    let end = mmt.endOf('month').format('YYYY-MM-DD')
    return { start, end }
}
export const Actual = () => {

    const { sendAxios, response, isLoading } = useAxios()
    const [data, setData] = useState([])
    const [cumul, setCumul] = useState(null)
    const [byCat, setByCat] = useState(null)
    const [dataIncomes, setDataIncomes] = useState([])
    const [cumulIncomes, setCumulIncomes] = useState(null);
    const [byCatIncomes, setByCatIncomes] = useState(null)
    const [diff, setDiff] = useState(null)
    const [viewMode, setViewMode] = useState(false)

    const update = (date) => {
        let result = getMonthDays(date)
        const query = qs.stringify({
            filters: {
                date: {
                    gte: result.start,
                    lte: result.end,
                }
            }
        }, {
            encodeValuesOnly: true, // prettify URL
        });
        setViewMode(false)
        sendAxios(`expenses_processed?${query}`)
    }
    const calculation = (data) => {

        const cumul = {
            eur: 0.00,
            chf: 0.00
        }
        if (!data) {
            return
        }

        data.map(depense => {
            const prices = depense.cumul
            cumul.eur += prices.eur
            cumul.chf += prices.chf
            return depense

        })
        setData(data)
        setCumul(cumul)
        setByCat(data)
    }
    const calculation2 = (data) => {
        const cumul = {
            eur: 0.00,
            chf: 0.00
        }
        if (data && data.length) {
            data.map(depense => {
                // switch number to integer to avoid float numbers calculation error
                const prices = depense.cumul
                cumul.eur += prices.eur * 100
                cumul.chf += prices.chf * 100
                return depense

            })
            cumul.eur = cumul.eur / 100
            cumul.chf = cumul.chf / 100
        }
        return {
            data: data,
            cumul: cumul,
        }
    }
    const calcDiff = (cumul, cumulIncomes) => {
        // console.log(cumul, cumulIncomes)
        if (!cumul || !cumulIncomes) {
            return false
        }
        let v = {
            eur: (cumulIncomes.eur * 100 - cumul.eur * 100) / 100,
            chf: (cumulIncomes.chf * 100 - cumul.chf * 100) / 100
        }
        // setDiff(v)
        return v
    }

    useEffect(() => {
        update(moment().toISOString());
    }, []);

    useEffect(() => {
        if (response && response.result) {
            // calculation(response.result)
            const outgoings = calculation2(response.result)
            const incomes = calculation2(response.incomes)
            setData(outgoings.data)
            setCumul(outgoings.cumul)
            setByCat(outgoings.data)
            setDataIncomes(incomes.data)
            setCumulIncomes(incomes.cumul)
            setByCatIncomes(incomes.data)
            const dif = calcDiff(outgoings.cumul,incomes.cumul)
            if(dif){
                setDiff(dif)
            }
        } else {
            setData([])
            setCumul(null)
            setByCat(null)
            setDataIncomes([])
            setCumulIncomes(null)
            setByCatIncomes(null)
            setDiff(null)

        }
    }, [response]);
    const handleSelectChange = (value) => {
        update(value)
    }
    return (
        <div className="Actual">
            <div className="titleH1Select">
                <h1>Recap du mois: </h1>
                <div className="contSelect">
                    <SelectDateByMonth onChange={handleSelectChange} />
                </div>
            </div>

            {!isLoading ? <AnimatePresence>
                {cumul ? <motion.div
                    variants={variant}
                    initial='initial'
                    animate='animate'
                    exit='exit'
                    transition={{ duration: 0.33, ease: "easeOut", delay: 0.33 }}
                >

                    <div className="totalAmountCont">
                        <div className="title">
                            <h1>Montant Total</h1>
                            <Switch
                                checkedChildren="Revenus"
                                unCheckedChildren="Depenses"
                                onChange={() => setViewMode(!viewMode)}
                            />
                        </div>
                        <Cumul title={'Entree'} data={cumulIncomes} clss="in" />
                        <Cumul title={'Sortie'} data={cumul} clss="out" />
                        <Cumul title={'Balance'} data={diff} check="true" />
                    </div>


                    {!viewMode ? <>
                        {byCat.length ? <div className="contByCat">
                            {byCat.map(cat => <div key={cat.id} className="catParentLine">
                                <div className="titleCont">
                                    <div className="title"><b>{cat.title}:</b> </div>
                                    <div className="price">{toCurrency(cat.cumul.chf)} CHF</div>
                                </div>
                                <SubCatsFromParent data={data} parentId={cat.id} />
                            </div>
                            )}
                        </div>
                            : <div className="noResult">Aucune dépense ce mois</div>
                        }
                    </> : <>
                        {byCatIncomes.length ? <div className="contByCat">
                            {byCatIncomes.map(cat => <div key={cat.id} className="catParentLine">
                                <div className="titleCont">
                                    <div className="title"><b>{cat.title}:</b> </div>
                                    <div className="price positif">{toCurrency(cat.cumul.chf)} CHF</div>
                                </div>
                                <SubCatsFromParent data={dataIncomes} parentId={cat.id} negPos/>
                            </div>
                            )}
                        </div>
                            : <div className="noResult">Aucun Revenu ce mois</div>
                        }
                    </>}

                </motion.div>
                    : <div className="noResult">Aucune dépense ce mois</div>
                }
            </AnimatePresence>
                : <></>
            }
        </div>
    )
}

export const SubCatsFromParent = ({ parentId, data ,negPos}) => {
    const parentData = data.filter(el => el.id === parentId)
    const items = parentData.map(el => {
        return el.items
    });
    const send = items.map(el => {
        return el.map(sousCat => {
            let keySub = `kSbuCat_${sousCat.categorie.id}`
            return <div
                className="subCatLine"
                key={keySub}
            >
                <div className="title">{sousCat.categorie.title}</div>
                <div className={`price ${negPos ? 'positif' : ''}`}>{toCurrency(sousCat.amounts.chf)} CHF</div>
            </div>
        })

    })
    return (
        <div>{send}</div>
    )

}

const numberFormat = (value) => {
    if (!value) return '0.00'
    let nbr = parseFloat(value)
    let result = nbr.toLocaleString('fr-FR', {
        style: 'decimal',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        roundingIncrement: 1,
    })
    return result
}
export const Cumul = ({ data, title, clss, check }) => {
    if (!data) {
        return
    }
    if (check) {
        if (data.chf < 0) {
            clss = 'negatif'
        } else if (data.chf > 0) {
            clss = 'positif'
        } else {
            clss = 'neutre'
        }
    }
    return (<div className={`price ${clss}`}>
        <h5>{title}</h5>
        {numberFormat(data.chf)} CHF<br /><small>{numberFormat(data.eur)} EUR</small>
    </div>)
}

export const SelectDateByMonth = ({ onChange }) => {
    let opts = []
    let mmnt = moment()
    let i = 0
    let n = null
    while (i <= (12 * 5)) {
        if (i === 0) {
            n = 0
        } else {
            n = 1
        }
        const m = mmnt.subtract(n, 'months')
        const month = {
            value: m.toISOString(),
            label: mmnt.format('MMM-YYYY')
        }
        i++
        opts.push(month)
    }
    const handleChange = (value) => {
        onChange(value)
    }
    return <Select
        defaultValue={opts[0]}
        style={{
            width: 120,
        }}
        onChange={handleChange}
        options={opts}
    />
}