import "../../scss/facturation.scss"
import { useFacturationContext } from "../../hooks/useFacturationContext"
import { Button, Empty } from "antd"
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons"
import MenuFacturation from "./Facturation/menuFactu"
import { useEffect } from "react"
import Invoices from "./Facturation/invoices"
import InvoicesFuture from "./Facturation/InvoicesFuture"
import Seances from "./Facturation/Seances"
import Customers from "./Facturation/Customers"
import { mmtFromYMD, toCurrency } from "../../utils"
import { usePut } from "../../hooks/usePut"

export const Facturation = () => {
    const { dispatch, dataFactu } = useFacturationContext()
    useEffect(() => {
        dispatch({ type: 'RESET' })
    }, [dispatch])
    return (
        <>
            <MenuFacturation />
            <div className="page">
                {dataFactu.navigation === 'invoices' && <Invoices />}
                {dataFactu.navigation === 'session' && <Seances />}
                {dataFactu.navigation === 'customers' && <Customers />}
                {dataFactu.navigation === '' && <>
                    <div className="homeFacturation">
                        <InvoicesFuture />
                        <InvoiceUnpaid />
                    </div>

                </>}
            </div>
            {/* <pre><code>
                {JSON.stringify(dataFactu.defaultpage,0,1)}
                </code></pre> */}
        </>
    )
}

export default Facturation


export const InvoiceUnpaid = () => {
    const { dispatch, dataFactu } = useFacturationContext()
    const { defaultpage } = dataFactu
    const handleModifyInvoice = (response) => {
        const val = response.unpaid
        // conyarsole.log('Val on factu:', val);
        dispatch({ type: 'UPDATE_UNPAID', payload: val })
    }
    // const { invoicesUnpaid } = defaultpage

    return (<div className="unpaid">

        {defaultpage && defaultpage.invoicesUnpaid
            ? <>
                {defaultpage.invoicesUnpaid.length
                    ? <>
                        <h4><small>{defaultpage.invoicesUnpaid.length}</small> Facture en attente de payement</h4>

                        <div className="supUnpaid">
                            <div className="rowUnpaid header">
                                <span>Facture num</span>
                                <span >Dates</span>
                                <span>Patient</span>
                                <span>Montant</span>
                            </div>
                            {defaultpage.invoicesUnpaid.map(invo => <div key={invo.id} className='rowUnpaid'>
                                <span>{invo.invoiceId}</span>
                                <span className="date">Creation: {mmtFromYMD(invo.invoiceDate).format('ll')} <br />
                                    Due: {mmtFromYMD(invo.dueDate).format('ll')}
                                </span>
                                <span>{invo?.customer?.patientName}</span>
                                {/* <span></span> */}
                                <span className="price">{toCurrency(invo.amount)} CHF <BtsPaidInvoice row={invo.id} onModify={handleModifyInvoice} /></span>
                            </div>)}
                        </div>
                    </>
                    : <div>
                        <h4>Facture en attente de payement</h4>
                        <div className="">
                        <Empty
                                description={
                                    <span>
                                        Aucune facture en retard de payement
                                    </span>
                                }
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                        </div>
                    </div>
                }



                {/* <pre><code>{JSON.stringify(defaultpage.invoicesUnpaid[0], 0, 1)}</code></pre> */}

            </>
            : <></>}
        {/* <pre><code>{JSON.stringify(defaultpage, 0, 1)}</code></pre> */}
        {/* <pre><code>{JSON.stringify(dataFactu, 0, 1)}</code></pre> */}
    </div>)
}

export const BtsPaidInvoice = ({ row, onModify = () => { } }) => {

    const { Put, response, isLoading } = usePut()
    const handleClickPaid = async (row) => {
        // console.log('handleClickPaid', row)
        // let path = `invoicePaid/347`
        let path = `invoicePaid/${row}`
        await Put(path, {
            setPaidDate: false,
            expSerRes: 'unpaid'
        })
    }
    const handleClickDelayedPaid = (row) => {
        alert('Work in Progress !')
        console.log('handleClickDelayedPaid', row)
    }
    useEffect(() => {
        if (response) {
            // console.log('Put Response:', response)
            onModify(response)
        }
    }, [response])

    return (<>
        <Button disabled={isLoading} shape="circle" onClick={() => handleClickPaid(row)}><CheckCircleOutlined /></Button>
        <Button disabled={isLoading} shape="circle" onClick={() => handleClickDelayedPaid(row)}><ClockCircleOutlined /></Button>

    </>)
}