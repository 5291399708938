import { Button, Drawer } from 'antd';
import { useEffect, useState } from "react";
import { sleep } from '../../utils';
import { PlusOutlined } from '@ant-design/icons';
// import { AddSpendDrawer } from './AddSpendDrawer2'; 
import { motion } from "framer-motion"
import FormAddSpend from './Forms/FormAddSpend';



export const DrawerAddSpend = ({ onCreateExpense }) => {
    const [open, setOpen] = useState(false);
    const [reset, setReset] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = async () => {
        setReset(true)
        await sleep(1)
        setReset(false)
        // await sleep(1)
        setOpen(false)
    };

    const onCloseRequest = (val) => {
        // console.log('On Close Request: DrawerAddSpend2', val)

        // if (val.data) {
        onCreateExpense()

        // }
        onClose()
    }
    return <>
        <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
        >
            <Button onClick={showDrawer}
                className="hButton"
                shape="circle"
                icon={<PlusOutlined />}
            />
        </motion.div>

        <Drawer
            title="Ajouter"
            width={720}
            onClose={onClose}
            open={open}
        >
            {open && <FormAddSpend onClose={onCloseRequest} />}


            {/* <AddSpendDrawer closeReset={reset} closeWraper={onCloseRequest} /> */}
        </Drawer>
    </>
}

export default DrawerAddSpend