import './../../scss/categories.scss'
import { Input, Modal } from "antd"
import { useState } from "react"
import { useEffect } from "react"
import { useGetInitial } from "../../hooks/useGetInitial"
import { usePutCategorie } from "../../hooks/usePutCategorie"
import { useSpendContext } from "../../hooks/useSpendContext"
import Select from 'react-select'
import { EditTwoTone } from '@ant-design/icons'
import { catSort } from '../../utils'

export const Categories = () => {
    const { getInitial } = useGetInitial()
    const { dataSpend } = useSpendContext()
    const [modal, setModal] = useState(false)
    const [parent, setParent] = useState(null)
    const [parentOriginal, setParentOriginal] = useState(null)
    const [children, setChildren] = useState(null)
    const [options, setOptions] = useState([])
    // const [defaultValue, setDefaultValue] = useState([])
    const { editCategorie } = usePutCategorie()

    const showModal = (categId, childId) => {
        // console.log(categId, childId)
        setParentOriginal(categId)
        setParent(categId)
        setChildren(childId)
        setOpt()
        setModal(true)
    }
    const setOpt = () => {
        if (options.length) {
            return
        }
        let opt = dataSpend.categories.map(categorie => {
            return {
                value: categorie.id,
                label: categorie.title
            }
        })
        // console.log('Option Created: ', opt)
        setOptions(opt)
    }
    const handleCatSelect = (value) => {
        setParent(value.value)
        // console.log('Selected ', value)
    }

    const handleOk = () => {
        // console.log('Original:', parentOriginal, 'new', parent)
        if (parentOriginal !== parent) {
            let data = {
                subCatId: children,
                newParent: parent,
                oldParent: parentOriginal
            }
            editCategorie(children, data)
        }
        setModal(false)
    }
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getInitial()
    }, [])
    return (
        <div className='categories'>
            <h3>Catégories</h3>
            <em>Double clic sur un titre pour le modifier.</em>
            <h4>Depenses</h4>
            <div className="categFlex">
                {dataSpend?.categories &&
                    dataSpend?.categories.map(categ =>
                        <div className='oneCat' key={categ.id}>
                            <div className="cat">
                                <EditableTitle elem={categ} />
                                {categ.childrens.length}x
                            </div>

                            {categ.childrens &&
                                catSort(categ.childrens).map(child =>
                                    <div key={`${categ.id}-${child.id}`} className="subCat"><EditableTitle elem={child} />
                                        <EditTwoTone onClick={() => showModal(categ.id, child.id)} />
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
            <h4>Revenus</h4>
          <div className="categFlex">
                {dataSpend?.categIncome &&
                    dataSpend?.categIncome.map(categ =>
                        <div className='oneCat' key={categ.id}>
                            <div className="cat">
                                <EditableTitle elem={categ} />
                                {categ.childrens.length}x
                            </div>

                            {categ.childrens &&
                                categ.childrens.map(child =>
                                    <div key={`${categ.id}-${child.id}`} className="subCat"><EditableTitle elem={child} />
                                        <EditTwoTone onClick={() => showModal(categ.id, child.id)} />
                                    </div>
                                )
                            }
                        </div>
                    )
                }
            </div>
            <Modal
                onCancel={() => setModal(false)}
                open={modal}
                onOk={() => handleOk()}
                title={'Modifier la categorie parent'}

            >

                <Select
                    style={{ width: '100%' }}
                    onChange={(v) => handleCatSelect(v)}
                    options={options}
                    value={options.find((el) => el.value === parent)}
                />
                {/* <pre><code>
                    {JSON.stringify(defaultValue, 0, 2)}
                </code></pre> */}
            </Modal>
            {/* <pre><code>
                {JSON.stringify(dataSpend.categIncome, 0, 2)}
            </code></pre> */}

        </div>
    )
}

export const EditableTitle = ({ elem }) => {
    const { editCategorie } = usePutCategorie()
    const [editable, setEditable] = useState(false)
    const [editValue, setEditValue] = useState(elem.title)
    const handleBlur = (el) => {
        // console.log(el)
        setEditable(!editable)
        if (editValue !== elem.title) {
            let data = { newTitle: editValue }
            editCategorie(elem.id, data)
            // console.log('title is edited')
        } else {
            // console.log('title is same same')

        }
    }
    const handleChange = (e) => {
        setEditValue(e.target.value)
    }
    return (
        <div className='editTitle'>
            {(editable)
                ? <Input type="text" value={editValue}
                    onBlur={() => handleBlur(elem)}
                    onChange={handleChange}
                    autoFocus
                />
                : <span onDoubleClick={() => setEditable(!editable)}>{(!elem.parent_id) ? <b>{editValue}</b> : editValue}</span>}
        </div>


    )
}