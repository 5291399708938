import { useEffect, useState } from "react";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { AnimatePresence, motion } from "framer-motion";
import Loading from "../Loading";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { sleep } from "../../utils";
import { usePost } from "../../hooks/usePost";

export const OnOff = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    useEffect(() => {
        localStorage.setItem("onlineStatus", JSON.stringify(navigator.onLine));

    }, [])

    useEffect(() => {
        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
            localStorage.setItem("onlineStatus", JSON.stringify(navigator.onLine));

        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }, [isOnline]);
    return (<>
        <AnimatePresence>
            {isOnline && (
                <motion.div className={`onlineStatue online`}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >Online Status: <CheckCircleTwoTone twoToneColor="#0ac99e" /></motion.div>

            )}
        </AnimatePresence>
        <AnimatePresence>
            {!isOnline && (
                <motion.div className={`onlineStatue offline`}
                    initial={{ opacity: 0, y: 100 }}
                    animate={{ opacity: 1, y: 0, transition: { duration: .5 } }}
                    exit={{ opacity: 0, y: 10 }}
                    transition={{ type: "spring", stiffness: 100, duration: 1.7 }}

                >Online Status: <CloseCircleTwoTone twoToneColor="#ff1493" /></motion.div>
            )}
        </AnimatePresence>
        {isOnline && <DoScheduledTasks />
        }
    </>)
}

const DoScheduledTasks = () => {
    // const [task, setTask] = useState(getStorageValue('scheduledTasks', []))
    const [tasks, setTasks] = useLocalStorage('scheduledTasks', [])
    const { Post } = usePost()

    const doTask = async (task) => {
        const { method } = task
        console.log(method)
        switch (method) {
            case 'POST':
                return await Post(task.path, task.value)
        }
    }

    useEffect(() => {
        const doAsync = async () => {
            console.log('call DoAsync')
            const task = tasks[0]
            const id = task.id
            console.log('id', id)
            const niou = JSON.parse(JSON.stringify(tasks.filter(task => task.id !== id)))
            console.log(task)
            await sleep(1000)
            await doTask(task)

            if (!niou) {
                setTasks([])

            } else {
                setTasks(niou)
            }
        }
        if (tasks.length) {
            console.log('Task To manage')
            doAsync()

        } else {
            console.log('pas de task a gerer ')
        }
    }, [tasks])

    return (<>
        {tasks.length && <div className="pendingTask">
            <div><Loading tip="synchronisation avec le serveur" /></div>
            <h2>Taches en Attentes de Validation par le serveur</h2>
            {tasks.map((task, i) => <div key={`task${i}`}>{task.id} - {task.title}</div>)}

        </div>}
    </>)

}