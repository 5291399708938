import { useState } from 'react'
import { useSpendContext } from './useSpendContext';
import { ApiUrl } from '../services'
import { axiosInstance as axios } from '../AxiosToken';
import { manageErrorPigApi , sleep} from '../utils';

export const useGetInitial = () => {
  const { dispatch } = useSpendContext()
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const getInitial = async () => {
    setIsLoading(true)
    setError(null)
    let url = ApiUrl()
    let path = 'initial'

    try {
      let lnk = `${url}/api/${path}`
      if (process.env.NODE_ENV !== 'production') {
        const sleepDuration = 100
        console.group('getInitial Request')
        console.log('getInitial: ', lnk);
        await sleep(sleepDuration)
        console.warn(`SLEEP ${sleepDuration}ms Enabled in getInitial.jsx`)
        console.groupEnd()
      }
      const respAxios = await axios.get(lnk)
      let result = respAxios.data
      // console.log('Get Initial Responses Axios', result)
      setResponse(result)
      setIsLoading(false)
      dispatch({ type: 'SET_DATA', payload: result })
    } catch (error) {
      let errorMessage = manageErrorPigApi(error)
      setError(error)
      dispatch({
        type: 'SET_NOTIF', payload: {
          message: (errorMessage) ? errorMessage : error.message,
          type: 'error'
        }
      })
      setIsLoading(false)
    }
  }
  return { getInitial, isLoading, error, response }
}


