import { useEffect } from "react";
import { useGetExpenses } from "../../hooks/useGetExpenses";
import { useSpendContext } from "../../hooks/useSpendContext";
import qs from 'qs'
import DrawerAddSpend from "./DrawerAddSpend2";
import Loading from "../Loading";
import TableExpenses from "./Tables/TablesExpenses";
export const Expenses = () => {
    const { dataSpend } = useSpendContext()
    const { getExpenses, isLoading } = useGetExpenses()

    const query = qs.stringify({
        sort: {
            date: 'desc',
            categorie_id: 'desc'
        }
    }, {
        encodeValuesOnly: true
    });
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        getExpenses(query)
    }, [])
    const handleCreateExpense = async (val) => {
        await getExpenses(query)
    }
    return (
        <div className="page" style={{position:'relative'}}>
            {isLoading ? <Loading cover /> : <>
                <div className="btAddExpense">
                    <DrawerAddSpend onCreateExpense={handleCreateExpense} />
                </div>
                <TableExpenses />
            </>}
        </div>
    )
}