import { createContext, useEffect, useReducer } from 'react'
export const FacturationContext = createContext()

export const FacturationReducer = (state, action) => {
  switch (action.type) {
    case 'RESET':
      return { dataFactu: doReset(state, dataFactuDefault) }
    case 'SET_NAVIGATION':
      return { dataFactu: setNavigation(state, action.payload) }
    case 'SET_DATA':
      return { dataFactu: setData(state, action.payload) }
    case 'INVOICE_CREATED':
      return { dataFactu: updateAfterCreateInvoice(state, action.payload) }

    case 'SET_PRODUCTS':
      return { dataFactu: setProducts(state, action.payload) }

    case 'SET_EDIT_SEANCE':
      return { dataFactu: setEditSeance(state, action.payload) }
    case 'RESET_EDITED_SEANCE':
      return { dataFactu: resetEditedSeance(state) }

    // Invoices 
    case 'SET_INVOICE_PAID':
      return { dataFactu: setInvoicePaid(state, action.payload) }
    case 'UPDATE_INVOICES_FUTURE':
      return { dataFactu: updateInvoicesFuture(state, action.payload) }
    case 'UPDATE_UNPAID':
      return { dataFactu: updateUnpaidList(state, action.payload) }

    case 'OFFLINE_ADD_SEANCE':
      return { dataFactu: offlineCreateSeance(state, action.payload) }
    // case 'SET_DATEYEAR':
    //   return { dataFactu: setDateYear(state, action.payload) }
    default:
      return state
  }

}
const dataFactuDefault = {
  data: [],
  navigation: ''
}

// OFFLINE
const offlineCreateSeance = ({ dataFactu }, newSeance) => {
  // get customer from dataSession
  const seance = newSeance[0]
  console.log('newSeance', seance)
  const el = dataFactu.customers.find(custom => custom.id === seance.customerId)
  seance.customer = el
  seance.patient = `(not Sync) ${el.firstName} ${el.lastName}`
  seance.date = seance.dateDb
  seance.id = 10000
  console.log('Customer', el);
  const seances = JSON.parse(JSON.stringify(dataFactu.session))
  seances.unshift(seance)
  dataFactu.session = seances
  // console.log('Nouvelle seance a ajouter: ', seance, dataFactu.session[0])
  /* attendu
  {
  "id": 919,
  "name": "Le 7 févr. 2023",
  "date": "2023-02-07",
  "price": 80,
  "invoiceId": null,
  "customerId": 39,
  "patient": "M. Jean-Marie Aoustin *",
  "customer": {
      "id": 39,
      "title": "Mme",
      "firstName": "Anne Marie",
      "lastName": "Aoustin",
      "companyName": "",
      "address": "Chemin des Coquelicots 2",
      "postalCode": "1009",
      "city": "PULLY (VD)",
      "concerne": "M. Jean-Marie Aoustin",
      "visible": 1
  }
} 
recu 
{
  "price": "80",
  "date": "2023-02-08T10:34:42.444Z",
  "customerId": 8,
  "name": "Le 8 févr. 2023",
  "dateDb": "2023-02-08"
}

  */
  return dataFactu
}


const setProducts = ({ dataFactu }, data) => {
  // console.log(data)
  // console.log('SET PRODUCT REQUEST ON CONTEXT')
  dataFactu.session = data
  return dataFactu
}

const setEditSeance = ({ dataFactu }, row) => {
  dataFactu.editedSeance = row
  return dataFactu
}

const resetEditedSeance = ({ dataFactu }) => {
  // console.log('Reset Seance in edition')
  delete (dataFactu.editedSeance)
  return dataFactu
}

const setNavigation = ({ dataFactu }, data) => {
  // console.log('Facturation Context:', data)
  dataFactu.navigation = data
  return dataFactu
}
/**
 * It takes an object with a dataFactu property and a response property, and returns a new object with
 * the same dataFactu property, but with the invoice with the id of the response's result's id property
 * set to paid
 * @param response - The response from the API.
 * @returns The dataFactu object is being returned.
 */

const updateUnpaidList = ({ dataFactu }, unpaidList) => {
  // console.log(unpaidList)
  dataFactu.defaultpage.invoicesUnpaid = unpaidList
  // console.log(JSON.stringify(dataFactu.defaultpage.invoicesUnpaid,0,1))
  return dataFactu
}

const updateInvoicesFuture = ({ dataFactu }, invoicesFuture) => {
  console.log(invoicesFuture)
  dataFactu.defaultpage = { ...dataFactu.defaultpage, invoicesFuture }
  return dataFactu
}

const setInvoicePaid = ({ dataFactu }, response) => {

  dataFactu.invoices = InvoiceResolver(response.invoices)

  return dataFactu
}

const updateAfterCreateInvoice = ({ dataFactu }, invoicesFuture) => {
  dataFactu.defaultpage = { ...dataFactu.defaultpage, invoicesFuture }
  return dataFactu

}
const doReset = ({ dataFactu }, plop) => {
  dataFactu = { ...plop }
  // console.log('RESET dataFactu')
  return dataFactu
}
const setData = ({ dataFactu }, data) => {
  if (data.requestType === 'invoices') {
    data.result = InvoiceResolver(data.result)
  }
  dataFactu[data.requestType] = data.result
  return dataFactu
}

const InvoiceResolver = (value) => {
  value.forEach(element => {
    let patient = ''
    if (!element.customer) {
      patient = 'Indefinie'

    } else {
      const customer = element.customer
      if (customer.concerne) {
        patient = `${customer.concerne} *`
      } else {
        patient = `${customer.firstName} ${customer.lastName}`

      }
    }
    element.patient = patient
  });
  return value
}


export const FacturationContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(FacturationReducer, {
    dataFactu: JSON.parse(JSON.stringify(dataFactuDefault)),
  })
  useEffect(() => {
    console.log('USE EFFECT IN CONTEXT FACTURATION')
    localStorage.setItem("dataFactu", JSON.stringify(state.dataFactu, 0, 2));

  }, [state])

  return (
    <FacturationContext.Provider value={{ ...state, dispatch }}>
      {children}
    </FacturationContext.Provider>
  )

}