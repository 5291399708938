import { useState } from "react"
import { useFacturationContext } from "../../../hooks/useFacturationContext"
// import { sleep } from "../../../utils"
import { useAxios as Axios } from "../../../hooks/useAxios"
import qs from 'qs'
import { useEffect } from "react"
import { Moment } from "../../../utils"
import { isOnline } from "../../../offLine"


export const MenuFacturation = () => {
    const [active, setActive] = useState('')
    const { dispatch } = useFacturationContext()
    const { sendAxios, response } = Axios()

    const handleClick = async (value) => {
        let query = ''
        setActive(value)
        if (value !== '') {
            query = qs.stringify({
                navigation: value
            }, {
                encodeValuesOnly: true,
            });
        } else {
            query = qs.stringify({
                navigation: 'defaultPage',
                t: Moment().format('x')
            }, {
                encodeValuesOnly: true,
            });
        }
        let lnk = `facturation?${query}`
        const online = isOnline();
        console.log('online status:', typeof online, online)
        // return
        if (online === false) {
            console.warn('Deconecté du serveur !', query)
            dispatch({ type: 'SET_NAVIGATION', payload: value })

        } else {
            console.log('ONN LINNNE ', online)
            await sendAxios(lnk)
            dispatch({ type: 'SET_NAVIGATION', payload: value })
        }
    }
    useEffect(() => {
        if (response && response.result) {
            dispatch({ type: 'SET_DATA', payload: response })
        } else {
            // dispatch({ type: 'SET_DATA', payload: [] })
        }
    }, [response]);

    useEffect(() => {
        handleClick('')
    }, [])


    return (
        <div className="navFactu">
            <button onClick={() => handleClick('')} className={(active === '') ? 'nbutton active' : 'nbutton'}><span>Résumé</span></button>
            <button onClick={() => handleClick('invoices')} className={(active === 'invoices') ? 'nbutton active' : 'nbutton'}><span>Factures</span></button>
            <button onClick={() => handleClick('session')} className={(active === 'session') ? 'nbutton active' : 'nbutton'}><span>Séances</span></button>
            <button onClick={() => handleClick('customers')} className={(active === 'customers') ? 'nbutton active' : 'nbutton'}><span>Clients</span></button>
        </div>
    )
}
export default MenuFacturation;