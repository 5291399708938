import { useEffect, useState } from "react"
import { useSpendContext } from "../hooks/useSpendContext"
import { motion, AnimatePresence } from "framer-motion"
const variant = {
    initial: { opacity: 0, x: -30 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, y: 50, transition: { duration: 0.73, ease: "easeIn" } }
}
export const Notification = () => {
    const { dataSpend, dispatch } = useSpendContext()
    const { notif } = dataSpend
    const [notifs, setNotifs] = useState([])
    const [timOut, setTimOut] = useState([])

    const doNotif = (notif) => {
        // isolation de notif
        let n = JSON.parse(JSON.stringify(notif))
        let id = Date.now()
        n.id = id
        let duree = (n.duration) ? n.duration : 3500
        const timeout = { id: id, value: setTimeout((who) => clearNotifTimeOut(who), duree, id) }
        setNotifs([...notifs, n])
        setTimOut([...timOut, timeout])
        dispatch({ type: "CLEAR_NOTIF", payload: null })

    }
    const clearNotifTimeOut = (who) => {

        dispatch({ type: 'CLEAR_TIMEOUT', payload: who })

    }
    const clearNotif = (id) => {
        // destroy timeout .
        let to = timOut.find(el => el.id === id)
        clearTimeout(to.value)
        let rest = timOut.filter(el => el.id !== id)
        setTimOut(rest)
        // destruction de la notif
        rest = notifs.filter(el => el.id !== id)
        setNotifs(rest)
        dispatch({ type: "CLEAR_NOTIF", payload: null })

    }


    useEffect(() => {
        const testNotif = () => {
            if (notif && notif.message) {
                // console.log('notif is setted:', notif)
                doNotif(notif)
            }
            if (notif && notif.id) {
                // console.log('timout id is setted:', notif)
                clearNotif(notif.id)

            }
        }

        testNotif()
        return
    }, [notif])

    return (
        <div className="notifieur">
            {/* Notif: {notifs.length} */}
            {/* <pre><code>{JSON.stringify(notifs, null, "\t")}</code></pre> */}
            {/* <pre><code>{JSON.stringify(timOut, null, "\t")}</code></pre> */}
            <AnimatePresence>
                {notifs.map(el => <motion.div
                    className={`notif ${(el.type) ? el.type : ''}`}
                    variants={variant}
                    initial='initial'
                    animate='animate'
                    exit='exit'
                    transition={{ duration: 0.33, ease: "easeOut", delay: 0.33 }}
                    key={el.id}
                >{el.message}</motion.div>)}
            </AnimatePresence>
        </div>
    )

}