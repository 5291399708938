import { useState } from 'react'
import { useSpendContext } from './useSpendContext';
import { ApiUrl } from '../services'
import { axiosInstance as axios } from '../AxiosToken';
import { manageErrorPigApi, sleep } from '../utils';

export const usePut = () => {
  const { dispatch } = useSpendContext()
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  const Put = async (path, data) => {
    setIsLoading(true)
    setError(null)
    let url = ApiUrl()
    // let path = 'expenses'

    try {
      let lnk = `${url}/api/${path}`
      if (process.env.NODE_ENV !== 'production') {
        const sleepDuration = 500
        console.group('Put Request')
        console.log('usePut: ', lnk);
        console.log('data: ', JSON.stringify(data));
        console.table(data)
        console.warn(`SLEEP ${sleepDuration}ms Enabled in usePut.jsx`)
        await sleep(sleepDuration)
        console.groupEnd()
      }
      const respAxios = await axios.put(lnk, data)
      let result = respAxios.data
      // console.log('getExpenses Responses Axios', result)
      setResponse(result)
      setIsLoading(false)
      if (result.message) {
        dispatch({
          type: 'SET_NOTIF', payload: {
            message: result.message,
            type: 'success'
          }
        })
      }
    } catch (error) {
      let errorMessage = manageErrorPigApi(error)
      console.log(error)
      setError(error)
      dispatch({
        type: 'SET_NOTIF', payload: {
          message: (errorMessage) ? errorMessage : error.message,
          type: 'error'
        }
      })
      setIsLoading(false)
    }
  }
  return { Put, isLoading, error, response }
}


