import { Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useSpendContext } from '../../hooks/useSpendContext';


export const Montant = ({ reset }) => {
    const { Option } = Select;
    const { dispatch } = useSpendContext()
    const [currency, setCurrency] = useState('CHF')
    const [amount, setAmount] = useState()
    const [amount2, setAmount2] = useState()
    const [edited, setEdited] = useState(false)
    const handleChangeAmount = (el) => {
        let amount = el.target.value
        setAmount(amount)
    }
    const handleChangeAmount2 = (el) => {
        let amount2 = el.target.value
        setAmount2(amount2)
    }

    const handlechangeCurrency = (v) => {
        setCurrency(v)
    }
    const update = () => {
        setEdited(false)
        let amt = toCurrency(amount2)
        dispatch({
            type: 'SET_AMOUNT', payload: {
                currency,
                amount2,
                amount: amt
            }
        })
    }
    const toCurrency = (value) => {
        if (!value) return ''
        let nbr = parseFloat(value)
        return nbr.toLocaleString('fr-FR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            roundingIncrement: 1,
        })
    }
    const selectAfter = (
        <Select defaultValue={currency} className="select-after" onChange={handlechangeCurrency} onBlur={update}>
            <Option value="EUR">Euro</Option>
            <Option value="CHF">CHF</Option>
        </Select>
    );
    useEffect(() => {
        if (reset === true) {
            // console.log('reset For Date', reset)
            setAmount(0)
            setAmount2(0)
            dispatch({ type: 'SET_AMOUNT', payload: null })
        }
    }, [reset])

    return (
        <>
            <div className="montant">
                <h4>Montant</h4>
                {edited ? (
                    <Input className='montantInput'
                        type='number'
                        addonAfter={selectAfter}
                        defaultValue={amount2}
                        value={amount2}
                        step="any" min="0"
                        onChange={handleChangeAmount2}
                        onBlur={update}
                    />
                ) : (

                    <Input
                        type='text' className='montantInput'
                        addonAfter={selectAfter}
                        value={toCurrency(amount2)}
                        defaultValue={toCurrency(amount2)}
                        // step="any" min="0"
                        onFocus={() => setEdited(true)}
                        readOnly
                    />
                )}
            </div>
        </>
    )
}