import { useContext } from "react"
import { FacturationContext } from "../Context/FacturationContext"

export const useFacturationContext = () => {
  const context = useContext(FacturationContext)

  if(!context) {
    throw Error('useFacturationContext must be used inside an FacturationContextProvider')
  }

  return context
}