
import YearRecap from "./yearRecap"
import FutureReccu from "./FutureReccu"
import { Actual } from "./ActualMonth"
import { useEffect } from "react"

export const HomeLogged = () => {
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[])
    return (
        <div className="page">
            <div className="infoTopContainer">
                <div className="content"><Actual /></div>
                <div className="content"><FutureReccu/> </div>
            </div>     
            <YearRecap />
        </div>
    )
}

