import { createContext, useReducer } from 'react'
import { resolveDepensesParents } from '../resolvers'
export const HomeContext = createContext()

export const homeReducer = (state, action) => {
  switch (action.type) {
    case 'CLEAR':
      return { dataSpend: doReset(state, dataSpendDefault) }
    case 'SET_DATA':
      return { dataSpend: setData(state, action.payload) }
    case 'SET_DATEYEAR':
      return { dataSpend: setDateYear(state, action.payload) }
    default:
      return state
  }
}
const dataSpendDefault = {
  data: [],
}


const setDateYear = ({ dataSpend }, date) => {
  // console.log('setDateYear is Called', date)
  dataSpend.dateYear = date
  return dataSpend
}

const setData = ({ dataSpend }, data) => {
  // reception des data de Strapi, traitement

  if (!data.result) {
    // console.log('HomeContext NO RESULT ')
    return dataSpend
  }
  // console.log('HomeContext Data Value: ',data.result);


  data = resolveDepensesParents(data.result)
  // data = catSort(data)

  // console.log('SetData After resolveDepensesParents', data)

  // console.log('dataSpend', data);
  dataSpend.data = data
  return dataSpend
}
const doReset = ({ dataSpend }, defaultValue) => {
  const newVal = { ...dataSpend, ...defaultValue }
  dataSpend = newVal
  // console.log('RESET dataSpend', dataSpend)
  return dataSpend
}


export const HomeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(homeReducer, {
    dataSpend: dataSpendDefault,
  })


  return (
    <HomeContext.Provider value={{ ...state, dispatch }}>
      {children}
    </HomeContext.Provider>
  )

}