import { mmtFromYMD, toCurrency } from "../../utils"
import { QRCode } from 'antd';

const calcSum = (products) => {
    let amount = 0
    let sum = products.reduce(function (accumulator, curValue) {
        return accumulator + curValue.price
    }, amount)
    return sum
}
export const FacturesPrint = ({ invoice }) => {

    return (
        <div className="A4">
            <div className="inA4">
                <div className="header">
                    <div className="sender">
                        <div><b>Lucie Plantevin</b></div>
                        5 chemin Emile-Dusonchet<br />
                        1256 Troinex<br />
                        Fixe : 022 784 02 40<br />
                        Portable : 076 403 57 10<br />
                        lucieplantevin@bluewin.ch
                    </div>
                    <div className="title">
                        <h2>Facture</h2>

                    </div>
                </div>
                <Recipient customer={invoice.customer} />
                <FactureDetail invoice={invoice} />
                <ProdcutsDetail invoice={invoice} />

                <div className="printFooter">
                    <FactQrCode invoice={invoice} />
                    <div className="iban">
                        <b>Coordonnées bancaires</b> : <br /> Raffeisen <br />BIC : RAIFCH22 <br /> IBAN : CH02 8080 8008 1456 3782 7
                    </div>
                </div>
            </div>

        </div>
    )
}
export const ProdcutsDetail = ({ invoice }) => {
    let amount = 0
    let sum = calcSum(invoice.products)
    return (
        <>
            <div className="headerOneProd">
                <div>Date</div>
                <div>Montant</div>
            </div>
            <div className="products">

                {invoice.products.map(prod =>
                    <div key={prod.id} className="oneProduct">
                        <div>{prod.name}</div>
                        <div>{toCurrency(prod.price)} CHF</div>
                    </div>
                )}
            </div>
            <div className="footerOneProd">
                <div>&nbsp;</div>
                <div>Total <b>{toCurrency(sum)} CHF</b></div>
            </div>
            <div className="dueDate">Facture à régler avant le {mmtFromYMD(invoice.dueDate).format('LL')}<br />
                <b>Avec mes remerciements</b>
                {invoice.message &&
                    <p className="message">{invoice.message}</p>
                }
            </div>
        </>
    )
}

export const FactureDetail = ({ invoice }) => {

    return (
        <div className="factDetail">
            <div>Facture n° <b>{invoice.invoiceId}</b></div>
            <div>Date {mmtFromYMD(invoice.invoiceDate).format('LL')}</div>
            <div>Periode : {invoice.periode}</div>
            {invoice.customer.concerne &&
                <div>Concerne {invoice.customer.concerne}</div>
            }
            <div><b>{invoice.title}</b></div>
        </div>
    )
}

export const Recipient = ({ customer }) => {
    return (
        <div className="recipient">
            <div>{customer.title} {customer.firstName} {customer.lastName}</div>
            {customer.companyName && <div>{customer.companyName}</div>}
            <div>{customer.address}</div>
            <div>{customer.postalCode} {customer.city}</div>
        </div>
    )
}

export const FactQrCode = ({ invoice }) => {
    /*
    Service Tag:	BCD
Version:	001
Character set:	1
Identification:	SCT
BIC:	BPOTBEB1
Name:	Red Cross
IBAN:	BE72000000001616
Amount:	EUR1
Reason (4 chars max):	CHAR
Ref of invoice:	Empty line or REFINVOICE
Or text:	Urgency fund or Empty line
Information:	Sample EPC QR code
    */
    let amount = calcSum(invoice.products)

    const QrBCD = {
        serviceTag: 'BCD',
        version: '001',
        characterSet: '1',
        identification: 'SCT',
        BIC: 'RAIFCH22',
        name: 'Lucie Plantevin',
        Iban: 'CH02 8080 8008 1456 3782 7',
        amount: `CHF${amount}`,
        reason: '',
        refOfInvoice: invoice.invoiceId,
        orText: '',
        info: 'Aucune info'
    }
    const QrSPC = {
        serviceTag: 'SPC',
        version: '0200',
        characterSet: '1',
        iban: 'CH0280808008145637827',
        s: 'S',
        nameOrSocial: 'Plantevin Lucie',
        rue: 'Emile Dusonchet',
        num: '5',
        PostalCode: '1256',
        ville: 'troinex',
        pays: 'CH',
        empty: '',
        empty2: '',
        empty3: '',
        empty4: '',
        empty5: '',
        empty6: '',
        empty7: '',
        montant: toCurrency(amount),
        currency: 'CHF',
        s2: '',
        debiteurName: '',
        debiteurRue: '',
        debiteurRueNum: '',
        debiteurPostalCode: '',
        debiteurVille: '',
        debiteurPays: '',
        bool: 'NON',
        // NON 
        empty8: '',
        message: invoice.invoiceId,
        epd: 'EPD',
        s1s10: `//S1/10/${invoice.invoiceId}`

    }
    let qrValue = ''
    for (const key in QrSPC) {
        qrValue += `${QrSPC[key]}\n`
    }


    return (
        <div className="qrcode">
            <b>Payer cette Facture</b>
            <QRCode
                value={qrValue}
                errorLevel='H'
                iconSize='24'
                icon="https://piggy.os-zone.com/ch.svg"

            />
        </div>
    )
}

export default FacturesPrint