import { useAxios } from "../../hooks/useAxios"
import moment from "moment/moment"
import { useEffect } from "react"
import { useState } from "react"
import { mmtFromYMD, toCurrency } from "../../utils"
import { QuestionCircleOutlined } from "@ant-design/icons"
import DrawerEditReccu from "./Forms/DrawerEditReccurent"


export const FutureReccu = () => {
    const { sendAxios, response, isLoading } = useAxios()
    const [recuData, setRecuData] = useState([])
    const [reccuIncomes, setReccuIncomes] = useState([])
    const [reccuInfo, setReccuInfo] = useState(null);

    const getNext = (data) => {
        let daynow = moment().format("D")
        const toShow = []
        data.forEach(el => {
            let [y, m, d] = el.dateStart.split('-')
            const reccu = JSON.parse(JSON.stringify(el.data))

            if (parseInt(d) > daynow) {
                let nxtDate = moment().set('date', parseInt(d))
                reccu.date = nxtDate.format('DD/MM/YYYY')
                reccu.nextDateU = nxtDate.format('X')
            } else {
                let nxtDate = moment().set('date', parseInt(d)).add(1, 'M')
                reccu.date = nxtDate.format('DD/MM/YYYY')
                reccu.nextDateU = nxtDate.format('X')
            }
            reccu.id = el.id
            reccu.categorie = el.categorie
            toShow.push(reccu)
        });
        toShow.sort((a, b) => a.nextDateU - b.nextDateU);

        data.sort((a, b) => a.nextU - b.nextU);
        // console.log(toShow, data)
        return data

    }
    const handleReccuInfo = (reccu) => {
        reccu.reccurent_expense_id = reccu.id
        // console.log(JSON.stringify(reccu,0,1))
        setReccuInfo(reccu)
    }
    useEffect(() => {
        sendAxios(`recurrent_expense`)
    }, [])
    useEffect(() => {
        if (response && response.outgoings) {
            const toShow = getNext(response.outgoings)
            setRecuData(toShow)
        }
        if (response && response.incomes) {
            const toShow = getNext(response.incomes)
            setReccuIncomes(toShow)
        }


    }, [response])
    return (
        <div style={{ padding: '0.3rem' }}>
            <h1 style={{ marginTop: 0 }}>Mouvements automatiques</h1>
            {!isLoading && <div>
                {recuData.length ? <div>
                    <h4>Dépenses</h4>
                    {recuData.map(reccu => <div key={`recu${reccu.id}`} className="reccuLine">
                        <div className="date">{mmtFromYMD(reccu.nextDate).format('ll')} </div>
                        <div className="text">{reccu.categorie.parent.title}</div>
                        <div className="text">{reccu.categorie.title} <QuestionCircleOutlined onClick={()=>handleReccuInfo(reccu)}/></div>
                        <div className="price">{toCurrency(reccu.data.amount)} {reccu.data.currency}</div>
                    </div>)}
                </div> : <div className="noResult">Auncune dépenses automatique attendue ce mois</div>}

                {reccuIncomes.length ? <div>
                    <h4 className="positif">Revenus</h4>
                    {reccuIncomes.map(reccu => <div key={`recuIn${reccu.id}`} className="reccuLine">
                        <div className="date">{mmtFromYMD(reccu.nextDate).format('ll')} </div>
                        <div className="text">{reccu.categorie.parent.title}</div>
                        <div className="text">{reccu.categorie.title} <QuestionCircleOutlined onClick={()=>handleReccuInfo(reccu)}/></div>
                        <div className="price positif">{toCurrency(reccu.data.amount)} {reccu.data.currency}</div>
                    </div>)}
                </div> : <div className="noResult">Auncun revenu automatique attendu ce mois</div>}
            </div>
            }
            <DrawerEditReccu reccurent={reccuInfo} onClose={()=>setReccuInfo(null)}/>
        </div>
    )
}

export default FutureReccu