import { useFacturationContext } from "../../../hooks/useFacturationContext"
import InvoicesTablePrint from "./InvoicesTablePrintVirtu"

export const Invoices = () => {

    const { dispatch, dataFactu } = useFacturationContext()
    const { invoices } = dataFactu
    return (
        <>
            {invoices && invoices.length ?
                <InvoicesTablePrint datas={invoices} />
                : ''}
        </>
    )
}

export default Invoices