import { Drawer, Switch, Button, Popconfirm } from "antd"
import { WarningOutlined, WarningTwoTone } from "@ant-design/icons"
import Decimal from "decimal.js"
import { useEffect, useState } from "react"
import { useGet } from "../../../hooks/useGet"
import { usePut } from "../../../hooks/usePut"
import { mmtFromYMD, toCurrency } from "../../../utils"
import Loading from "../../Loading"

const getLastPayment = (list) => {
    list.sort(
        (p1, p2) => (p1.id < p2.id) ? 1 : (p1.id > p2.id) ? -1 : 0);
    return mmtFromYMD(list[0].date).format('ll')
}
const getTotalAmount = (list) => {
    let nbr = new Decimal(0.00);
    list.expenses.forEach(element => {
        let y = nbr.plus(element.amount)
        nbr = y
    });
    return nbr
}

export const DrawerEditReccu = ({ reccurent, onClose = () => { } }) => {

    const { Get, isLoading, response, error } = useGet()
    const { Put, isLoading: putLoading, response: putResponse } = usePut()
    const [reccuId, setReccuId] = useState(null)
    const [value, setValue] = useState(null)

    const confirmStop = async () => {
        // console.log('confirmation d interuption', reccuId)
        const path = `recurrent_expense/${reccuId}`
        await Put(path, { status: false })
    }

    useEffect(() => {
        if (reccurent) {
            const { reccurent_expense_id } = reccurent
            setReccuId(reccurent_expense_id)
            const path = `recurrent_expense/${reccurent_expense_id}`
            // console.log('Load data: ', path)
            Get(path)
        }

    }, [reccurent])
    useEffect(() => {
        if (putResponse) {
            // console.log('put Response', putResponse)
            setValue(putResponse.reccuExpense)
        }
    }, [putResponse])

    useEffect(() => {
        if (response) {
            setValue(response.reccuExpense)
        }
    }, [response])
    return (<>
        <Drawer
            open={(reccurent)}
            onClose={onClose}
            placement='left'
            destroyOnClose
            closable={false}
        >

            {isLoading ? <Loading /> :
                <>
                    {value && <>
                        <div style={{ textAlign: 'right' }}>
                            <Switch
                                disabled
                                checkedChildren="Actif"
                                unCheckedChildren="Terminé"
                                checked={value.status}
                            />
                        </div>
                        <HeaderReccu reccu={value} />
                        {value?.expenses && (value.expenses.length > 0)
                            ? <div className="supReccuResum">
                                {value.expenses.map(el => <div key={el.id} className="rowReccuExpenses">
                                    <span>{mmtFromYMD(el.date).format('ll')}</span>
                                    <span className="price">{toCurrency(el.amount)} {el.currency}</span>
                                </div>)}
                                <div className="rowReccuExpenses">
                                    <span>&nbsp;</span>
                                    <span>Total: <b>{toCurrency(getTotalAmount(value))}  {value.expenses[0].currency}</b></span>
                                </div>
                            </div>
                            : 'Pas encore actif'
                        }
                        {value.status &&
                            <div className="btReccuSup">
                                <Popconfirm
                                    placement="top"
                                    title="L'interuption est irréversible"
                                    description="Veux tu continuer ?"
                                    onConfirm={confirmStop}
                                    icon={<WarningTwoTone twoToneColor="#ff1493" />}
                                    okText="Oui"
                                    cancelText="Non"
                                >
                                    <Button danger icon={<WarningOutlined />}>désactiver ce payement</Button>
                                </Popconfirm>

                            </div>
                        }

                        {/* <pre>{JSON.stringify(value.expenses,0,2)}</pre> */}

                    </>}
                </>
            }
        </Drawer>
    </>)
}

export default DrawerEditReccu

export const HeaderReccu = ({ reccu }) => {
    return (<>
        <h3>{reccu.expenses.length}/{(reccu.times) ? reccu.times : '∞'}x
            {reccu.status
                ? <> depuis le {mmtFromYMD(reccu.dateStart).format('ll')}</>
                : <> du {mmtFromYMD(reccu.dateStart).format('ll')} au {(reccu.expenses.length) ? getLastPayment(reccu.expenses) : ''} </>
            }</h3>
        {/* <h4>gfgf</h4> */}
        {/* <pre>
            {JSON.stringify(reccu, 0, 2)}
        </pre> */}
    </>)
}