import { useState } from 'react'
import { useSpendContext } from './useSpendContext';
import { ApiUrl } from '../services'
import { axiosInstance as axios } from '../AxiosToken';
import { manageErrorPigApi } from '../utils';

export const useGetSeance = () => {
  const { dispatch } = useSpendContext()
  const [error, setError] = useState(null)
  const [response, setResponse] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  const Get = async (path,filter) => {
    setIsLoading(true)
    setError(null)
    let url = ApiUrl()
    // let path = 'expenses'

    try {
      if(filter){
        path += `?${filter}`
      }
      let lnk = `${url}/api/${path}`
      console.log('useGet',lnk);
      const respAxios = await axios.get(lnk)
      let result = respAxios.data
      // console.log('getExpenses Responses Axios', result)
      setResponse(result)
      setIsLoading(false)
      dispatch({ type: 'SET_DATA_EXPENSES', payload: result })
    } catch (error) {
      let errorMessage = manageErrorPigApi(error)
      setError(error)
      dispatch({
        type: 'SET_NOTIF', payload: {
          message: (errorMessage) ? errorMessage : error.message,
          type: 'error'
        }
      })
      setIsLoading(false)
    }
  }
  return { Get, isLoading, error, response }
}


