import { useFacturationContext } from '../../../hooks/useFacturationContext';
import { SendOutlined, DownOutlined, LeftOutlined } from '@ant-design/icons';
import { motion, AnimatePresence } from "framer-motion"
import { DatePicker, Checkbox, Form, Button, Input, Select, Row, Col, Empty } from 'antd';
import { useState, useEffect } from 'react';
import { usePost } from '../../../hooks/usePost';
import { mmtFromYMD, sleep } from '../../../utils';
import dayjs from 'dayjs';
import DrawerAddSeance from './DrawerAddSeance';

const InvoicesFuture = () => {
    const { dataFactu } = useFacturationContext()
    const { defaultpage } = dataFactu

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    return (
        <div>
            <div>
                <h4>Ajouter une Séance <DrawerAddSeance /></h4>
            </div>
            <div className='futureSup'>
                {/* <h2>Facture a faire</h2> */}
                {defaultpage && defaultpage.invoicesFuture ? <>
                    {defaultpage.invoicesFuture.length
                        ? <>
                            <h4>{defaultpage.invoicesFuture.length} Facture{(defaultpage.invoicesFuture.length > 1) ? 's' : ''} à créer </h4>
                            <div className="futureBill">
                                {defaultpage.invoicesFuture.map((invo, i) => {
                                    const clef = (+new Date).toString(36).slice(-10)
                                    const kle = `${clef}${i}${invo.id}`
                                    // console.log(kle)
                                    return <CheckSeances customer={invo} key={kle} />
                                }

                                )}
                            </div>
                        </>
                        : <>
                            <h4>Facture à créer</h4>
                            <Empty
                                description={
                                    <span>
                                        Aucune séance non facturée
                                    </span>
                                }
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                            />
                        </>
                    }

                </> : <></>}
            </div>

        </div>
    )
}


const CheckboxGroup = Checkbox.Group;
const optionsSelect = [
    {
        value: 'CDCTV',
        label: 'Cours de chant, technique vocale',
    },
    {
        value: 'SMT',
        label: 'Séances de musicothérapie',
    },
]

const variants = {
    open: { opacity: 1, x: 0, rotate: 0 },
    closed: { opacity: 1, x: "-100%", rotate: 360 },
}

const CheckSeances = ({ customer }) => {
    const { dispatch } = useFacturationContext()
    const defaultSelect = 'SMT'
    const defaultDate = dayjs().endOf('month')
    let defaultCheckedList = []
    const [typeSelect, setTypeSelect] = useState(defaultSelect)
    const [date, setDate] = useState(defaultDate)
    const [fold, setFold] = useState(false)
    const [indeterminate, setIndeterminate] = useState(false)
    const [checkAll, setCheckAll] = useState(true)
    const { Post, response, error } = usePost()
    const seances = customer.toBill
    const customerId = customer.id
    let plainOptions = [];

    const exist = []
    if (seances) {
        // console.log(seances)
        plainOptions = seances.map((el, i) => {
            let txt = '';
            let dateFr = mmtFromYMD(el.date).format('ll')
            // console.log(dateFr)
            if (exist.includes(dateFr)) {
                txt = dateFr + '.' + i
            } else {
                txt = dateFr
            }
            exist.push(txt)
            const result = { value: el.id, label: txt }
            return result

        })
        // clone isolé , => checked all 
        defaultCheckedList = plainOptions.map(el => el.value);
    }
    const [checkedList, setCheckedList] = useState(defaultCheckedList)

    // console.log(customerId, plainOptions, defaultCheckedList)

    const onFinish = async (values) => {
        console.clear()
        values['type'] = typeSelect
        values['date'] = (date) ? date.format('YYYY-MM-DD') : null
        values['seances'] = checkedList
        values.id = customerId
        // console.log('Success:', values);
        let path = `invoice`
        await Post(path, values)


    }
    const handleSelect = (val) => {
        // console.log(val)
        setTypeSelect(val)
    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    }


    const onChange = (list) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < defaultCheckedList.length);
        setCheckAll(list.length === plainOptions.length);
    };
    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? defaultCheckedList : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const handleChangeDate = (date, dateString) => {
        // console.log(date.toISOString(), dateString)
        // return
        if (date) {
            let value = date.toISOString().split('T')[0]
            // console.log(value, defaultDate.toISOString().split('T')[0])
        }
        setDate(date)
    }


    useEffect(() => {
        if (response) {
            console.log('Response Found', response)
            dispatch({ type: 'INVOICE_CREATED', payload: response.invoicesFuture })
            // setDispatchType(null);
        }
    }, [response])
    useEffect(() => {
        if (error) {
            console.log('Error Response', error);
        }
    }, [error])

    return (
        <div className='oneBill'>
            <Form
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                // wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600, margin: '0 auto' }}

            >
                <b>{customer.title} {customer.firstName} {customer.lastName}</b><small> ( {seances.length} séance{(seances.length > 1) ? 's' : ''})</small> <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                    tout
                </Checkbox>
                <div className='defaultResumeValue'>
                    <span
                        onClick={() => setFold(!fold)}
                        style={{ cursor: 'pointer' }}
                    ><b>{date ? date.format('DD/MM/YYYY') : ' -------------- '}</b> - {optionsSelect.find(el => el.value === typeSelect).label}</span>
                    <motion.span
                        onClick={() => setFold(!fold)}
                        animate={fold ? "open" : "closed"}
                        duration={0.5}
                        variants={variants}
                        style={{ cursor: 'pointer' }}

                    >{fold ? <DownOutlined style={{ color: '#AA0000', fontSize: '1.1em', fontWeight: 'bold' }} /> : <LeftOutlined />}</motion.span>
                </div>
                <AnimatePresence>
                    {fold && <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: '100%' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: .32 }}
                    >
                        <Form.Item
                            style={{ marginBottom: 0 }}
                        >
                            <Form.Item
                                label="Type"
                                style={{
                                    display: 'inline-block',
                                    width: 'calc(50%)',
                                    paddingRight: '1em'
                                }}
                            // wrapperCol={{
                            //     offset: 0,
                            //     span: 24,
                            // }}
                            >
                                <Select
                                    defaultValue={defaultSelect}
                                    onChange={handleSelect}
                                    options={optionsSelect} />


                            </Form.Item>
                            <Form.Item
                                label="Date"
                                style={{
                                    display: 'inline-block',
                                    width: 'calc(50% )',
                                }}
                                wrapperCol={{
                                    // offset:13,
                                    span: 21,
                                }}
                            >
                                <DatePicker
                                    value={date}
                                    style={{ width: '100%' }}
                                    format="DD/MM/YYYY"
                                    onChange={(date, dateString) => handleChangeDate(date, dateString)}
                                />
                            </Form.Item>
                        </Form.Item>


                        <Form.Item
                            label="Message"
                            name="message"
                        >
                            <Input />
                        </Form.Item>
                    </motion.div>
                    }
                </AnimatePresence>
                <Form.Item
                    label="Séances"
                    style={{ marginBottom: 0 }}
                    wrapperCol={{
                        offset: 1,
                        // span: 3,

                    }}>
                    <CheckboxGroup
                        // options={plainOptions}
                        value={checkedList}
                        onChange={onChange}
                    // style={{ width: '100%' }}
                    >
                        <Row >
                            {plainOptions.map(option =>
                                <Col xs={{
                                    span: 0,
                                    offset: 0,
                                }}
                                    lg={{
                                        span: 8,
                                        // offset: 2,
                                    }} key={option.value}>
                                    <Checkbox value={option.value}>{option.label}</Checkbox>
                                </Col>)
                            }
                        </Row>
                    </CheckboxGroup>
                </Form.Item>



                <Form.Item
                    wrapperCol={{
                        offset: 18,
                        // span: 3,
                    }}
                >

                    <Button htmlType='submit' disabled={(checkedList.length < 1)}>creer <SendOutlined /></Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default InvoicesFuture