import moment from "moment"

export const isOnline = () => {
    const status = JSON.parse(localStorage.getItem('onlineStatus'))
    return status
}
export const offlineStorage = (title, method, path, value) => {

    const key = 'scheduledTasks'

    // recupere les data dans le localStorage
    const tasks = getStorageValue(key, [])
    console.log(tasks)
    let id = tasks.length + 1
    const time = moment()
    let data = {
        id,
        time,
        method,
        path,
        value,
        title
    }

    tasks.push(data)
    localStorage.setItem("scheduledTasks", JSON.stringify(tasks));
    console.log('OffLine Storage', tasks.length)

}
export const getStorageValue = (key, defaultValue) => {
    // getting stored value
    const saved = localStorage.getItem(key);
    const initial = JSON.parse(saved);
    return initial || defaultValue;
}