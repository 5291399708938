import { useState } from "react"
import { useLogin } from "../hooks/useLoginAxios"
import { ReactComponent as ImgBg } from '../svg/background.svg'
import '../scss/login.scss'
import { AnimPiggy } from "./AnimPiggy"
const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [focus, setFocus] = useState(null)
  const { login, error, isLoading } = useLogin()

  const handleSubmit = async (e) => {
    e.preventDefault()

    await login(email, password)
  }

  return (
    <div className="loginPage">
      <div className="supSvg">
        <ImgBg />

      </div>
      <form className="login" onSubmit={handleSubmit}>

        <AnimPiggy />
        <h2 className="piggyTitle">PIGGY</h2>
        <h4>Login to continue </h4>

        <div className="inputsup">
          <label className={(focus === 'address' || email !== '') ? 'blur' : ''}>Email address:</label>
          <input
            type="email"
            onFocus={() => setFocus('address')}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

        </div>

        <div className="inputsup">
          <label className={(focus === 'password' || password !== '') ? 'blur' : ''}>Password:</label>
          <input
            type="password"
            onFocus={() => setFocus('password')}
            // onFocusOut={()=> setFocus('')}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
        </div>

        <button disabled={isLoading}>Log in</button>
        <div className="error">{error && <p>{error}</p>}</div>

      </form>
    </div>

  )
}

export default Login